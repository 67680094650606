import {RootState} from "../../../redux/selectors/rootSelectors";
import {createSelector, Selector} from "reselect";
import {AdminCupsMapPoint, CupsMerchantSummary} from "../../services/interfaces";

export const getAdminCupsState = (state: RootState) => state.admin.cups;


export const getAdminCupsTableList: Selector<RootState, CupsMerchantSummary[]> = createSelector(
    getAdminCupsState,
    (adminCupsState) => adminCupsState.table.merchants
);

export const getAdminCupsTableMaxPage: Selector<RootState, number> = createSelector(
    getAdminCupsState,
    (adminCupsState) => adminCupsState.table.maxPage
);

export const getAdminCupsTableCount: Selector<RootState, number> = createSelector(
    getAdminCupsState,
    (adminCupsState) => adminCupsState.table.merchantsCount
);

export const getAdminCupsMapPoints: Selector<RootState, AdminCupsMapPoint[]> = createSelector(
    getAdminCupsState,
    (adminCupsState) => adminCupsState.mapPoints
);