import {
    addMerchantDeposit,
    createMerchantPoint,
    downloadAccountingDocumentPDF,
    getAccountingDocuments,
    getAccountingDocumentsXLSX,
    getAccountingFilters,
    getMerchant,
    getMerchantDashboard,
    getMerchantHistoryDeposit,
    getMerchantPoint,
    getMerchantPointDashboard,
    googleMapsSearch,
    searchCompanyByNIP,
    sendRemoveTerminalRequest,
    updateMerchant,
    updateMerchantPoint
} from "../http/merchant";
import React from "react";
import {
    AccountingDocumentResponse,
    AccountingFilterOptions,
    Coordinates,
    DashboardData,
    GetDashboardDataProps,
    GoogleMapsSearchData,
    GoogleMapsSearchResult,
    HistoryDeposit,
    MasterCashbackLevel,
    Merchant,
    MerchantPoint,
    MerchantPointUpdateData,
    MerchantUpdateData,
    NIPSearchResponse,
    OrderType,
    Transaction
} from "../services/interfaces";
import {isEmpty, minBy} from "lodash";
import {store} from "../redux/store";
import {
    importMerchantPointAction,
    setDashboardDataAction,
    setFirstTransactionDateAction,
    setIsMerchantSelectedAction,
    setMasterCashbackLevelsAction,
    setMerchantAction,
    setMerchantPointAction
} from "../redux/merchant";
import {NavigateFunction} from "react-router-dom";
import {HOME_PAGE} from "../services/dictionaries/paths";
import {
    setResponseNotificationErrorAction,
    setResponseNotificationSavedAction,
    setScreenLoadingAction
} from "../redux/navigation";
import {getCashbackLevels, getMerchantFirstTransaction, getMerchantPointFirstTransaction} from "../http/transactions";
import {getCurrentMerchantPoint, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {prepareB64FileToPDF, prepareB64FileToXLSX, prepareURLParamForDashboardData} from "../services/utils";
import {saveAs} from "file-saver";


export const searchCompanyByNIPHandler = (
    nip: string,
    setMerchant: React.Dispatch<React.SetStateAction<Merchant>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<string>>
) => {
    setLoading(true);
    searchCompanyByNIP(nip).then((response: NIPSearchResponse) => {
        setMerchant(prevState => ({
            ...prevState,
            ...response
        }));
        setLoading(false);
        setError('')
    }).catch(error => {
        setLoading(false);
        setError(error.detail || 'unknown_error');
        setMerchant(prevState => ({
            ...prevState,
            nip
        }));
    })
};


export const googleMapsSearchHandler = (
    query: string,
    location: Coordinates | null,
    setSearchData: React.Dispatch<React.SetStateAction<GoogleMapsSearchData>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<string>>,
) => {
    setLoading(true);
    googleMapsSearch(query, location).then((response: GoogleMapsSearchResult[]) => {
        setSearchData(prevState => ({
            ...prevState,
            results: isEmpty(response) ? [{
                place_id: '',
                place_name: '',
                address: '',
                longitude: '',
                latitude: ''
            }] : response
        }));
        setLoading(false);
    }).catch(error => {
        setLoading(false);
        setError(error.message || 'unknown_error')
    })
};


export const getMerchantHandler = async (setMerchantAsSelected: boolean = false, shouldGetFirstMerchantPoint: boolean = false) => {
    getMerchant().then((merchant: Merchant) => {
        store.dispatch(setMerchantAction(merchant));
        store.dispatch(setIsMerchantSelectedAction(setMerchantAsSelected));
        if (shouldGetFirstMerchantPoint) {
            const id = minBy(merchant.merchant_points, "id")?.id;
            id && getMerchantPointHandler(id, setMerchantAsSelected)
        }
        getMerchantFirstTransactionDateHandler()
    }).catch(error => {
        console.error(error);
    })
};


export const createMerchantPointAndUpdateMerchantHandler = (
    merchantPoint: MerchantPoint,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    successCallback: () => void,
    errorCallback: () => void,
    merchantUpdateData?: MerchantUpdateData,
) => {
    setLoading(true);
    const callback = () => {
        setLoading(false);
        successCallback()
    };
    createMerchantPoint(merchantPoint).then((data) => {
        if (merchantUpdateData)
            updateMerchant(merchantUpdateData).then(() => {
                getMerchantPointHandler(data.merchant_point_id);
                getMerchantHandler().then(() => {
                    callback()
                });
            }).catch(error => {
            });
        else {
            getMerchantPointHandler(data.merchant_point_id);
            getMerchantHandler().then(() => {
                callback();
            });
        }
    }).catch(error => {
        setLoading(false);
        errorCallback();
        console.error(error);
    })
};

export const updateMerchantPointHandler = (
    merchantPointId: number,
    merchantPointUpdateData: MerchantPointUpdateData,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    successCallback: () => void,
    errorCallback: () => void,
    merchantUpdateData?: MerchantUpdateData,
) => {
    setLoading(true);
    const callback = () => {
        getMerchantPointHandler(merchantPointId);
        getMerchantHandler().then(() => {
            setLoading(false);
            successCallback();
        });
    };
    updateMerchantPoint(merchantPointId, merchantPointUpdateData).then((response) => {
        if (merchantUpdateData)
            updateMerchant(merchantUpdateData).then(() => {
                callback();
            }).catch(error => {
            });
        else {
            callback();
        }
    }).catch(error => {
        setLoading(false);
        errorCallback();
        console.error(error);
    })
};

export const getMerchantPointHandler = (merchantPointId: number, setMerchantAsSelected: boolean = false) => {
    getMerchantPoint(merchantPointId).then((merchantPoint: MerchantPoint) => {
        store.dispatch(setMerchantPointAction(merchantPoint));
        store.dispatch(setIsMerchantSelectedAction(setMerchantAsSelected));
        getMerchantPointFirstTransactionDateHandler(merchantPointId)
    }).catch(error => {
        console.error(error);
    })
};


export const importMerchantPointDataHandler = (
    merchantPointId: number,
    navigateToAddPage: () => void,
) => {
    getMerchantPoint(merchantPointId).then((merchantPoint: MerchantPoint) => {
        store.dispatch(importMerchantPointAction(merchantPoint));
        navigateToAddPage();
    }).catch(error => {
        console.error(error);
    })
};

export const getDashboardDataHandler = (props: GetDashboardDataProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    store.dispatch(setScreenLoadingAction(true));

    const urlParam = prepareURLParamForDashboardData(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id)
        getMerchantPointDashboard(merchantPoint.id, urlParam).then((dashboardData: {
            previous: DashboardData,
            current: DashboardData
        }) => {
            store.dispatch(setDashboardDataAction(dashboardData));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        });
    else
        getMerchantDashboard(urlParam).then((dashboardData: {
            previous: DashboardData,
            current: DashboardData
        }) => {
            store.dispatch(setDashboardDataAction(dashboardData));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        });

};

export const sendRemoveTerminalRequestHandler = (terminalId: number, callback: () => void) => {
    sendRemoveTerminalRequest(terminalId).then(() => {
        callback();
    }).catch(error => {
        console.error(error);
    })
};

export const getMerchantFirstTransactionDateHandler = () => {
    getMerchantFirstTransaction().then((transaction: Transaction | null) => {
        store.dispatch(setFirstTransactionDateAction(transaction ? transaction.trade_date : null))
    })
};

export const getMerchantPointFirstTransactionDateHandler = (merchantPointId: number) => {
    getMerchantPointFirstTransaction(merchantPointId).then((transaction: Transaction | null) => {
        store.dispatch(setFirstTransactionDateAction(transaction ? transaction.trade_date : null))
    })
};


export const getMasterCashbackLevelsHandler = () => {
    getCashbackLevels().then((cashbackLevels: MasterCashbackLevel[]) => {
        store.dispatch(setMasterCashbackLevelsAction(cashbackLevels))
    }).catch(error => {
        console.error(error)
    });
}

export const addMerchantDepositHandler = (amount: number, callback: (deposit: {
    deposit: number,
    deposit_requirement: number
}) => void) => {
    addMerchantDeposit(amount).then((data) => {
        callback(data)
    }).catch(error => {
        console.log(error)
    });
}

export const getMerchantHistoryDepositHandler = (setHistory: React.Dispatch<React.SetStateAction<HistoryDeposit[]>>, callback: () => void) => {
    getMerchantHistoryDeposit().then((response) => {
        setHistory(response)
        callback()
    }).catch(error => {
        console.log(error)
    })
}

export const getAccountingDocumentsHandler = (
    setAccountingDocuments: React.Dispatch<React.SetStateAction<AccountingDocumentResponse>>,
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: OrderType,
    filterString: string,
    query: string,
    callback: () => void
) => {
    getAccountingDocuments(
        page,
        pageSize,
        sortBy,
        sortOrder,
        filterString,
        query
    ).then((response) => {
        setAccountingDocuments(response)
        callback()
    }).catch(error => {
        console.log(error)
    })
}

export const getAccountingFiltersHandler = (
    setFilters: React.Dispatch<React.SetStateAction<AccountingFilterOptions>>,
) => {
    getAccountingFilters().then((response) => {
        setFilters({
            ...response
        })
    }).catch(error => {
        console.log(error)
    })
}

export const getAccountingDocumentsXLSXHandler = (
    sortBy: string,
    sortOrder: OrderType,
    query: string,
    filterString: string,
) => {
    getAccountingDocumentsXLSX(
        sortBy,
        sortOrder,
        query,
        filterString
    ).then((response) => {
        const blob = prepareB64FileToXLSX(response.content);
        const filename = response.filename;
        saveAs(blob, filename)
    }).catch(error => {
        console.log(error)
    })
}

export const downloadAccountingDocumentPDFHandler = (documentID: number) => {
    downloadAccountingDocumentPDF(documentID).then((response) => {
        const blob = prepareB64FileToPDF(response.content);
        const filename = response.filename;
        saveAs(blob, filename)
    }).catch(error => {
        console.log(error)
    })
}