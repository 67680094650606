import {DateRange, OrderType} from "../../services/interfaces";
import {store} from "../../redux/store";
import {setScreenLoadingAction} from "../../redux/navigation";
import {getAdminCupsMapPoints, getAdminCupsTable} from "../http/cups";
import {setAdminCupsMerchantPointsAction, setAdminCupsTableAction} from "../redux/adminCups";
import {prepareURLParamForTable} from "../../services/utils";
import {AdminGlobalFilters} from "../services/interfaces";
import {isEmpty} from "lodash";

export const getAdminCupsMapHandler = (dateRange: DateRange,
                                       globalFilters: AdminGlobalFilters,
                                       query: string,
                                       filters: string) => {
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    if (query !== '') {
        urlParam.append('q', query);
    }

    let newFilters = filters;

    if (!isEmpty(globalFilters.merchantPoints))
        newFilters += '&merchant_points_ids=' + globalFilters.merchantPoints.join(',');
    if (!isEmpty(globalFilters.serviceTypes))
        newFilters += '&service_types=' + globalFilters.serviceTypes.join(',');
    if (newFilters !== '')
        urlParam.append('filters', newFilters);
    getAdminCupsMapPoints(urlParam.toString()).then(data => {
        store.dispatch(setAdminCupsMerchantPointsAction(data));
        store.dispatch(setScreenLoadingAction(false));
    }).catch(error => {
        console.error(error);
        store.dispatch(setScreenLoadingAction(false));
    });
};

export const getAdminCupsTableHandler = ({page, query, sort, order, filters, pageSize, dateRange, globalFilters}: {
    page: number,
    query: string,
    sort: string,
    order: OrderType,
    filters: string,
    pageSize: number,
    dateRange: DateRange,
    globalFilters: AdminGlobalFilters
}) => {
    const urlParam = prepareURLParamForTable({page, query, sort, order, filters, pageSize});
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    let newFilters = filters;
    if (!isEmpty(globalFilters.merchantPoints))
        newFilters += '&merchant_points_ids=' + globalFilters.merchantPoints.join(',');
    if (!isEmpty(globalFilters.serviceTypes))
        newFilters += '&service_types=' + globalFilters.serviceTypes.join(',');
    if (newFilters !== '')
        urlParam.append('filters', newFilters);
    getAdminCupsTable(urlParam.toString()).then((data) => {
        store.dispatch(setAdminCupsTableAction(data))
    }).catch(error => {
        console.error(error);
    })
};

