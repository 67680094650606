import React, {useEffect, useState} from 'react';
import Input from "../common/Input";
import {Coordinates, GoogleMapsSearchData, GoogleMapsSearchResult, MerchantPoint, Address} from "../../services/interfaces";
import {googleMapsSearchHandler} from "../../handlers/merchantHandler";
import {googleQueryValidation} from "../../services/validators";
import ErrorComponent from "../common/ErrorComponent";
import {isEmpty} from "lodash";
import MapModal from "../modals/MapModal";
;


interface Props {
    merchantPoint: MerchantPoint
    setMerchantPoint: React.Dispatch<React.SetStateAction<MerchantPoint>>
    isMerchantConfigured: boolean
}

const MerchantPointGoogleMapsSearch = ({merchantPoint, setMerchantPoint, isMerchantConfigured}: Props) => {
    const [searchData, setSearchData] = useState<GoogleMapsSearchData>({query: '', results: []});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isResultSelected, setIsResultSelected] = useState<boolean>(false);
    const [location, setLocation] = useState<Coordinates | null>(null);
    const [isMapModalOpen, setIsMapModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };

        getLocation();
    }, []);

    useEffect(() => {
        setSearchData({query: merchantPoint.address, results: []});
        setIsResultSelected(true);
    }, [merchantPoint.address]);

    useEffect(() => {
        if (!isResultSelected) {
            const delayDebounceFn = setTimeout(async () => {
                if (searchData.query.length > 3) {
                    googleMapsSearchHandler(searchData.query, location, setSearchData, setLoading, setError)
                } else {
                    setSearchData(prevState => ({...prevState, results: []}));
                    setError('');
                }
            }, 1000);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchData.query, location, isResultSelected, setError, setLoading, setSearchData]);


    const onResultSelect = (result: GoogleMapsSearchResult) => {
        setIsResultSelected(true);
        setMerchantPoint(prevState => ({...prevState, ...result}));
        setSearchData({query: result.address, results: []});
    };

    interface QueryErrorKey {
        QueryError: string
    }

    const errorMessage: Record<keyof QueryErrorKey, string> = {
        QueryError: "Wprowadź minimum 3 znaki, aby wyszukać adres",
    };

    const handleQueryChange: React.Dispatch<React.SetStateAction<GoogleMapsSearchData>> = (newSearchData) => {
        setIsResultSelected(false);
        return setSearchData(newSearchData);
    };

    const formatAddress = (address: Address) => address.street + ', ' + address.zip_code + ' ' + address.city + ', Polska';

    return (
        <div className="google-maps-search">
            {error && <ErrorComponent error={error}/>}
            <Input<GoogleMapsSearchData, 'query'>
                value={searchData}
                onChange={handleQueryChange}
                name='query'
                errorMessage={errorMessage}
                label={isMerchantConfigured ? 'Adres punktu' : 'Adres pierwszego punktu'}
                validate={googleQueryValidation}
                isLoading={loading}
                isRequired
                showError
                tooltipTitle="Wyszukiwanie adresu po nazwie lokalizacji"
                tooltipBody="Jeśli nie znajdziesz swojego adresu w wynikach wyszukiwania, będziesz mógł wprowadzić dane adresowe ręcznie klikając w odpowiednią opcję na liście wyników"
            />
            {!isEmpty(searchData.results) &&
                <ul className="google-maps-results">
                    {searchData.results.map(result =>
                        result.address ?
                            <li onClick={() => onResultSelect(result)}
                                key={`Result${result.place_id}`}
                                className="google-maps-result"
                            >
                                <span
                                    className="google-maps-result-label">{result.place_name + ' ' + result.address}</span>
                            </li> :
                            <li key="NoResulutsFound"
                                className="google-maps-result"
                                onClick={() => setIsMapModalOpen(true)}
                            >
                                <span
                                    className="google-maps-result-label">Nie znaleziono adresu dla podanej frazy. Kliknij aby wprowadzić dane ręcznie</span>
                            </li>
                    )}
                </ul>
            }
            <MapModal
                isOpen={isMapModalOpen}
                onClose={()=>setIsMapModalOpen(false)}
                userLocation={location}
                onSaveAddress={(location, address) => onResultSelect({
                          latitude: location.lat.toString(),
                          longitude: location.lng.toString(),
                          address: formatAddress(address),
                          place_id: '',
                          place_name: ''
                      })}/>
        </div>
    );
};

export default MerchantPointGoogleMapsSearch;