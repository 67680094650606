import React, {useMemo, useState} from "react";
import TargetGroupModal from "../modals/target_group/TargetGroupModal";
import {useSelector} from "react-redux";
import {getCurrentTargetGroup, getTargetGroups} from "../../redux/selectors/merchantSelector";
import {find, isEmpty} from "lodash";
import {icons} from "../../services/images";
import {TargetGroup} from "../../services/interfaces";
import {TARGET_GROUP_DEFAULT_STATE} from "../../redux/merchant";
import {
    getSelectedFiltersFromTargetGroup,
    resetCurrentTargetGroupAndStatisticsFilterOrder
} from "../../services/statisticsHelpers";
import {Tooltip} from "react-tooltip";
import {useLocation} from "react-router-dom";
import {HISTORY_PAGE} from "../../services/dictionaries/paths";

interface Props {
    showTargetGroupChanges?: boolean
}

const TargetGroupButton = ({showTargetGroupChanges = false}: Props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showModalList, setShowModalList] = useState<boolean>(false);
    const [editCurrent, setEditCurrent] = useState<boolean>(false);
    const [editData, setEditData] = useState<TargetGroup>(TARGET_GROUP_DEFAULT_STATE);
    const targetGroups = useSelector(getTargetGroups);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const location = useLocation();
    const isTransactionHistoryPage = location.pathname.includes(HISTORY_PAGE);

    const showFormWithData = (targetGroup: TargetGroup) => {
        setIsModalOpen(true);
        setShowModalList(false);
        setEditData(targetGroup)
    };

    const CreateGroupButton = () => (
        <>
            <Tooltip id="CreateTargetGroupTooltip" className="tooltip" noArrow={true}>
                <p className="tooltip-title">Utwórz grupę docelową</p>
                <p className="tooltip-body">Grupa docelowa służy do filtrowania wyświetlanych wyników. Pozwala zawężyć
                    dane o poszczególne charakterystyki klientów.</p>
            </Tooltip>
            <div className="target-group-button create"
                 data-tooltip-id="CreateTargetGroupTooltip"
                 onClick={() => {
                     showFormWithData(TARGET_GROUP_DEFAULT_STATE);
                     setEditCurrent(false);
                 }}>
                <img src={icons.addWhiteIcon} alt="Add Target Group"/>
                <p className="label-small">Utwórz grupę docelową</p>
            </div>
        </>
    );

    const SelectGroupButton = () => (
        <>
            <Tooltip id="SelectTargetGroupTooltip" className="tooltip" noArrow={true} place="bottom">
                <p className="tooltip-title">Wybierz grupę docelową</p>
                <p className="tooltip-body">Grupa docelowa służy do filtrowania wyświetlanych wyników. Pozwala zawężyć
                    dane o poszczególne charakterystyki klientów.</p>
            </Tooltip>
            <div className="target-group-button select"
                 data-tooltip-id="SelectTargetGroupTooltip"
                 onClick={() => {
                     setIsModalOpen(true);
                     setShowModalList(true);
                     setEditCurrent(false);
                 }}>
                <img src={icons.selectTargetGroup} alt="Filters"/>
                <p className="label-small">Wybierz grupę docelową</p>
            </div>
        </>
    );

    const SelectedGroupButton = () => {
        const foundTargetGroup = find(targetGroups, t => t.id === currentTargetGroup.id);
        const selectedGroup = foundTargetGroup && !showTargetGroupChanges ? foundTargetGroup : currentTargetGroup;
        const selectedFilters: string[] = useMemo(() => {
            return getSelectedFiltersFromTargetGroup(selectedGroup)
        }, [selectedGroup]);
        return (
            <>
                <Tooltip id="SelectedTargetGroupFiltersTooltip" className="tooltip" noArrow={true} place="bottom">
                    <p className="tooltip-body">Prezentowane wyniki <b>{isTransactionHistoryPage ? "na wykresie" : ""}</b> dotyczą tylko wybranej grupy docelowej</p>
                    <p className="tooltip-title">Grupa docelowa:<p className="tooltip-body"> {selectedGroup.name} </p></p>
                    <p className="tooltip-title">Wybrane Filtry:</p>
                    {selectedFilters.map((filter, index) =>
                        <p className="tooltip-body" key={`SelectedTargetGroupFiltersTooltipKey${index}`}>
                            {filter}</p>
                    )}
                </Tooltip>
                <div className={`target-group-button selected`} data-tooltip-id="SelectedTargetGroupFiltersTooltip">
                    <span onClick={() => {
                        showFormWithData(selectedGroup);
                        setEditCurrent(true)
                    }}
                          className="name">{selectedGroup.name}</span>
                    <div className="remove">
                        <img src={icons.removeWhiteIcon} alt=""
                             onClick={resetCurrentTargetGroupAndStatisticsFilterOrder}/>
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            {isEmpty(targetGroups) ?
                <CreateGroupButton/> :
                <SelectGroupButton/>
            }
            {currentTargetGroup.id &&
                <SelectedGroupButton/>
            }
            <TargetGroupModal isOpen={isModalOpen}
                              setIsOpen={setIsModalOpen}
                              setShowList={setShowModalList}
                              showList={showModalList}
                              editCurrent={editCurrent}
                              editData={editData}
            />
        </>
    )
};

export default TargetGroupButton;