import {isOpeningHoursFilled} from "../../services/helpers";
import Button from "../common/Button";
import {OpeningHours} from "../../services/interfaces";
import OpeningHoursModal from "./OpeningHoursModal";
import React, {useState} from "react";
import {DayOfWeekMapping} from "../../services/dictionaries/enums";
import {sortByDayOfWeek} from "../../services/dates";


interface Props {
    openingHours: OpeningHours[];
    setOpeningHours: (openingHours: OpeningHours[]) => void;
}

const OpeningHoursSection = ({openingHours, setOpeningHours}: Props) => {
    const [isOpeningHoursModalOpen, setIsOpeningHoursModalOpen] = useState(false);
    const toggleOpeningHoursModal = () => setIsOpeningHoursModalOpen(!isOpeningHoursModalOpen);
    const sortedOpeningHours = sortByDayOfWeek(openingHours);
    return (
        <div className="input-wrapper opening-hours">
            <div className="hours-header">
                <label htmlFor="OpeningHours">Godziny otwarcia <span
                    className="required-mark">*</span></label>
                {isOpeningHoursFilled(openingHours) &&
                    <span className="link link-button" onClick={toggleOpeningHoursModal}>
                        Zmień godziny
                    </span>
                }
            </div>
            {isOpeningHoursFilled(openingHours) ?
                <ul className="opening-hours-table">
                    {sortedOpeningHours.map((openingHoursDay, index) =>
                        <li key={`openingHoursDay${index}`}>
                            <p className="day-name">{DayOfWeekMapping[openingHoursDay.day_of_week]}:</p>
                            <div className="day-value">{openingHoursDay.is_closed ?
                                <p className="closed">Zamknięte</p> :
                                <p>{openingHoursDay.open_time} - {openingHoursDay.close_time}</p>}</div>
                        </li>
                    )}
                </ul> :
                <Button type="Default" label="Ustal godziny" onClick={toggleOpeningHoursModal}/>
            }
            <OpeningHoursModal
                isOpen={isOpeningHoursModalOpen}
                setIsOpen={setIsOpeningHoursModalOpen}
                openingHours={openingHours}
                setOpeningHours={setOpeningHours}
            />
        </div>
    )
};


export default OpeningHoursSection;