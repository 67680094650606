import DateRangePicker from "../common/DateRangePicker";
import {useDispatch, useSelector} from "react-redux";
import {
    getBarChartSelected,
    getChartInterval,
    getCurrentMerchantPoint,
    getDatePreset,
    getDateRange,
    getIsMerchantSelected,
    getMerchant,
    getPreviousPeriod
} from "../../redux/selectors/merchantSelector";
import React, {useEffect, useState} from "react";
import ChangePill from "../common/ChangePill";
import DateIntervalComposedChart from "../common/chart/DateIntervalComposedChart";
import {
    ChartInterval,
    CupAction,
    CupActionSort,
    DatePreset,
    DateRange,
    OrderType,
    TableHeader
} from "../../services/interfaces";
import {getHeaderTitle} from "../../services/helpers";
import {
    getCupDashboardData,
    getCupTableCount,
    getCupTableData,
    getCupTableMaxPage
} from "../../redux/selectors/cupSelector";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../services/numbers";
import TimeRangeLabel from "../common/TimeRangeLabel";
import Table from "../common/Table";
import CupActionTableRow from "./CupActionTableRow";
import {
    downloadCupActionsTableHandler,
    getMerchantCupDashboardHandler,
    getMerchantCupTableHandler
} from "../../handlers/cupHandlers";
import CupActionsFiltersModal from "../modals/filters/CupActionsFiltersModal";
import {
    setBarChartSelectedAction,
    setChartIntervalAction,
    setDatePresetAction,
    setDateRangeAction,
    setPreviousPeriodAction
} from "../../redux/merchant";
import PreviousPeriodSelect from "../common/PreviousPeriodSelect";
import {icons} from "../../services/images";
import CupsManagementModal from "./CupsManagmentModal";
import NotificationPopup from "../common/NotificationPopup";
import {Tooltip} from "react-tooltip";

const Cups = () => {
    // Table State
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [cupsManagementModalOpen, setCupsManagementModalOpen] = useState<boolean>(false);


    // Common selectors
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    // Dashboard selectors
    const dateRange: DateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const chartInterval: ChartInterval = useSelector(getChartInterval);
    const previousPeriod = useSelector(getPreviousPeriod);
    const barChartSelected = useSelector(getBarChartSelected);
    const dashboardData = useSelector(getCupDashboardData);

    // Table Selectors
    const count: number = useSelector(getCupTableCount);
    const tableData: CupAction[] = useSelector(getCupTableData);
    const maxPage: number = useSelector(getCupTableMaxPage);


    const headers: TableHeader<CupActionSort>[] = [
        {name: 'ID klienta', sort: 'user_id', digit: false},
        {name: 'ID akcji', sort: 'id', digit: false},
        {name: 'Data', sort: 'date', digit: false},
        {name: 'Adres punktu', sort: 'address', digit: false},
        {name: 'Rodzaj Akcji', sort: 'type', digit: false},
        {name: 'Liczba kubków', sort: 'number_of_cups', digit: true},
        {name: 'Stan magazynowy', sort: 'cup_state', digit: true},
    ];


    // Dashboard Effects
    useEffect(() => {
        getMerchantCupDashboardHandler({dateRange, previousPeriod, chartInterval})
    }, [dateRange, isMerchantSelected, merchant, merchantPoint, previousPeriod, chartInterval]);

    // Table Effects
    useEffect(() => {
        getMerchantCupTableHandler({page, query, sort, order, filters: filterString, pageSize})
    }, [page, query, sort, order, isMerchantSelected, merchant, merchantPoint, filterString, pageSize]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);


    const dispatch = useDispatch();

    const tooltipFormat = (value: number) => {
        const formattedNumber = formatNumber(value, WHOLE_NUMBER_FORMAT);
        let suffix = '';
        if (value === 1)
            suffix = 'akcja';
        else if ([2, 3, 4].includes(value))
            suffix = 'akcje';
        else
            suffix = 'akcji';
        return formattedNumber + ' ' + suffix;
    };


    if (!merchant)
        return <div/>;

    const CupsManagementButton = (
        <>
            <CupsManagementModal
                isOpen={cupsManagementModalOpen}
                setIsOpen={setCupsManagementModalOpen}
                getTableData={() => getMerchantCupTableHandler({
                    page,
                    query,
                    sort,
                    order,
                    filters: filterString,
                    pageSize
                })}
            />
            <Tooltip id="cups-management" className="tooltip" noArrow={true}>
                <p className="tooltip-title">Zarządzaj kubkami</p>
                <p className="tooltip-body">Aby zarządzać kubkami, musisz wybrać punkt, w którym chcesz zmieniać stan
                    magazynowy</p>
            </Tooltip>
            <div className={`manage ${merchantPoint && !isMerchantSelected ? '' : 'disabled'}`}
                 data-tooltip-id="cups-management"
                 data-tooltip-place="top-end"
                 onClick={() => {
                     if (merchantPoint && !isMerchantSelected) {
                         setCupsManagementModalOpen(true)
                     }
                 }}>
                <img src={icons.cogWhiteIcon} alt="Manage"/>
                <p className="label-small">Zarządzaj kubkami</p>
            </div>
        </>
    );

    return (
        <div className="dashboard-content cups">
            <NotificationPopup/>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Ekokubki - {getHeaderTitle(isMerchantSelected, merchant, merchantPoint)}</h2>
                </div>
                <div className="header-section">
                    <PreviousPeriodSelect previousPeriod={previousPeriod}
                                          onChange={(period) => dispatch(setPreviousPeriodAction(period))}/>
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                    />
                </div>
            </div>
            <div className="block-wrapper chart-block-wrapper">
                <div className="block chart-block big-chart-block">
                    <div className="value-section">
                        <h3>Liczba akcji</h3>
                        <div className="value-wrapper">
                            <span
                                className="standard-value">{formatNumber(dashboardData.count, WHOLE_NUMBER_FORMAT)}</span>
                            <ChangePill value={dashboardData.count}
                                        prevValue={dashboardData.prevCount}
                            />
                            <TimeRangeLabel/>
                        </div>
                    </div>
                    <DateIntervalComposedChart chartData={dashboardData.current}
                                               prevChartData={dashboardData.previous}
                                               chartType="absolute"
                                               chartInterval={chartInterval}
                                               setChartInterval={(chartInterval) => dispatch(setChartIntervalAction(chartInterval))}
                                               barChartSelected={barChartSelected}
                                               setBarChartSelected={(selected) => dispatch(setBarChartSelectedAction(selected))}
                                               previousPeriod={previousPeriod}
                                               tooltipFormat={tooltipFormat}
                    />
                </div>
            </div>
            <h3>Lista wszystkich akcji</h3>
            <div className="block-wrapper">
                <Table
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    query={query}
                    setQuery={setQuery}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    count={count}
                    filters={filterString}
                    setFilters={setFilterString}
                    maxPage={maxPage}
                    renderRows={() => tableData.map((cupAction, index) =>
                        <CupActionTableRow cupAction={cupAction}
                                           filters={filterString}
                                           getTableData={() => getMerchantCupTableHandler({
                                               page,
                                               query,
                                               sort,
                                               order,
                                               filters: filterString,
                                               pageSize
                                           })}
                                           key={`CupActionRow${index}`}/>
                    )}
                    downloadHandler={downloadCupActionsTableHandler}
                    headers={headers}
                    Modal={CupActionsFiltersModal}
                    extraButtons={CupsManagementButton}
                    hasActionButton={true}
                />
            </div>
        </div>
    )
};

export default Cups;