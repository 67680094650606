import React, {useState} from "react";
import {cloudFrontUrl, getServiceTypeIcon, icons} from "../../../services/images";
import moment from "moment";
import {getReservationTime, isPastReservation} from "../../../services/dates";
import {MerchantPoint, ReservationDetails} from "../../../services/interfaces";
import {ReservationStatusEnum} from "../../../services/dictionaries/enums";
import {getMainServiceType} from "../../../admin/services/utils";
import {getServiceTypeTile} from "../../../services/dictionaries/serviceTypesDict";
import {formatNumber} from "../../../services/numbers";
import Button from "../../common/Button";
import ErrorComponent from "../../common/ErrorComponent";
import {cancelReservationHandler} from "../../../handlers/bookingHandlers";
import getNotificationMessage from "../../../services/dictionaries/notification";
import ConfirmChangeModal from "../../modals/ConfirmChangeModal";


interface Props {
    reservation: ReservationDetails;
    merchantPoint: MerchantPoint;
    saveCallback: () => void;
    error: boolean;
    setError: (error: boolean) => void;
}

const ReservationDetailsSection = ({reservation, merchantPoint, saveCallback, error, setError}: Props) => {
    const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
    const appointmentDate = moment(reservation.appointment_date).locale('pl');
    const formattedDay = appointmentDate.format('YYYY-MM-DD');
    const formattedTime = getReservationTime(reservation.appointment_date, reservation.duration);
    const dayName = appointmentDate.format('dddd');
    const formattedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    const mainServiceType = getMainServiceType(merchantPoint);
    const serviceTypeDetails = getServiceTypeTile(mainServiceType?.name || '');

    let iconHeight, iconWidth = 41;
    if (!merchantPoint.logo_image) {
        iconHeight = serviceTypeDetails ? serviceTypeDetails.iconHeight : 41;
        iconWidth = serviceTypeDetails ? serviceTypeDetails.iconWidth : 41;
    }

    return (
        <div className="booking-details">
            <p className="booking-date">{formattedDay} {formattedDayName}</p>
            <p className="booking-status">
                Status: <span
                className={reservation.status}>{reservation.status in ReservationStatusEnum ? ReservationStatusEnum[reservation.status] : ''}</span>
            </p>
            <p className="booking-note">
                {reservation.status === 'completed' && 'Usługa została wykonana pomyślnie'}
                {reservation.status === 'canceled' && 'Wizyta została anulowana'}
                {['placed', 'paid'].includes(reservation.status) && 'Przygotuj się na przybycie klienta o wskazanej godzinie.'}
            </p>
            <div className="employee">
                <div>
                    <img src={icons.userProfileIcon} alt=""/>
                </div>
                <span className="employee-name">{reservation.employee.name} - <span>wykonawca</span></span>
            </div>
            <div className="booking-summary-row">
                <div>
                    <div className="logo">
                        <img src={merchantPoint.logo_image ?
                            cloudFrontUrl + merchantPoint.logo_image :
                            getServiceTypeIcon(mainServiceType?.name || '', true)
                        }
                             alt=""
                             height={iconHeight}
                             width={iconWidth}
                        />
                    </div>
                    <div>
                        <span>{merchantPoint.name}</span>
                        <span>{reservation.service_name}</span>
                    </div>
                </div>
                <div>
                    <span>{formatNumber(reservation.price_at_time)}</span>
                    <span>{formattedTime}</span>
                </div>
            </div>
            <div className="horizontal-divider"/>
            <div className="cashback-row">
                <span>Cashback do opłacenia</span>
                <div>
                    <img src={icons.cashbackCoinIcon} alt=""/>
                    <span>{formatNumber(1.2)}</span>
                    {/* TODO Calculate Cashback    */}
                </div>
            </div>
            <div className="amount-row">
                <span>Kwota zakupów</span>
                <div>
                    <span>{formatNumber(reservation.price_at_time)}</span>
                </div>
            </div>
            {error && <ErrorComponent error="unknown_error"/>}
            {(reservation.status === 'paid' || reservation.status === 'placed') && !isPastReservation(reservation) &&
                <div className="buttons">
                    <Button label="Anuluj wizytę"
                            type="Cancel"
                            onClick={() => setShowCancelConfirmation(true)}
                    />
                </div>
            }
            <ConfirmChangeModal isOpen={showCancelConfirmation}
                                setIsOpen={setShowCancelConfirmation}
                                title="Jesteś pewien?"
                                onConfirm={() => cancelReservationHandler({
                                    reservationId: reservation.id,
                                    callback: saveCallback,
                                    errorCallback: () => setError(true)
                                })}
                                body={getNotificationMessage("cancel_reservation_info")}
                                confirmLabel="Tak, Anuluj"
            />
        </div>
    )
};

export default ReservationDetailsSection;