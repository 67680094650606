import {CupAction} from "../../services/interfaces";
import {parseQueryString} from "../../services/utils";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../services/numbers";
import {CupActionTypeEnum} from "../../services/dictionaries/enums";
import {icons} from "../../services/images";
import ActionModal from "../modals/ActionModal";
import React, {useMemo, useRef, useState} from "react";
import {setCupActionErrorHandler} from "../../handlers/cupHandlers";
import AdminMessageModal from "../modals/AdminMessageModal";


interface Props {
    cupAction: CupAction,
    filters: string
    getTableData: () => void;
}

const CupActionTableRow = ({cupAction, filters, getTableData}: Props) => {

    const [isAdminMessageModalOpen, setIsAdminMessageModalOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const actionRef = useRef<(HTMLTableCellElement | null)>(null);
    const formattedDate = new Date(cupAction.date).toLocaleDateString();
    const [appUserCupCountConflict, setAppUserCupCountConflict] = useState(false);

    let cupActionType = 'Nieznany';
    if (cupAction.type in CupActionTypeEnum) {
        cupActionType = CupActionTypeEnum[cupAction.type];
    }

    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];

    const toggleActionModal = () => {
        if (!cupAction.error) {
            const item = actionRef.current;
            if (item) {
                const {right, bottom} = item.getBoundingClientRect();
                setActionModalPosition({
                    x: right,
                    y: bottom,
                });
            }
            setIsActionModalOpen(!isActionModalOpen);
        }
    };

    const cupCountConflictCallback = (withAppUser: boolean) => {
        setAppUserCupCountConflict(withAppUser);
        setIsAdminMessageModalOpen(true);
    };


    const setAsError = () => {
        toggleActionModal();
        setCupActionErrorHandler(cupAction, getTableData, cupCountConflictCallback)
    };

    const adminMessageTitle = useMemo(() => {
        return `Oznaczenie akcji (id=${cupAction.id}) jako błąd jest niemożliwe`
    }, [cupAction]);

    const adminBodyTitle = useMemo(() => {
        let body = `Oznaczenie akcji (id=${cupAction.id}) jako błąd powoduje ujemny stan liczby kubków `;

        if (appUserCupCountConflict && cupAction.user)
            body += `u klienta (id=${cupAction.user?.id})`;
        else
            body += `w punkcie (id=${cupAction.merchant_point.id})`;

        return body
    }, [cupAction, appUserCupCountConflict]);

    const rowClassName = cupAction.error ? "with-error" : (cupAction.corrected_action_id ? 'correction' : '');
    return (
        <tr className={rowClassName}>
            {!excludedColumns.includes('user_id') && <td className="client-id-cell">{cupAction.user ? cupAction.user.id : '--'}</td>}
            {!excludedColumns.includes('id') && <td className="client-id-cell">{cupAction.id}</td>}
            {!excludedColumns.includes('date') && <td>{formattedDate}</td>}
            {!excludedColumns.includes('address') &&
                <td>{cupAction.merchant_point.address}</td>}
            {!excludedColumns.includes('type') &&
                <td className={`cashback-status-cell`}>
                    {cupActionType}{cupAction.corrected_action_id ? ` (ID=${cupAction.corrected_action_id})`: ''}
                </td>
            }
            {!excludedColumns.includes('number_of_cups') &&
                    <td className="cell-digit">{formatNumber(cupAction.number_of_cups, WHOLE_NUMBER_FORMAT)}</td>}
            {!excludedColumns.includes('cup_state') &&
                    <td className="cell-digit">{formatNumber(cupAction.cup_state, WHOLE_NUMBER_FORMAT)}</td>}
            <td className="actions-button-cell" ref={actionRef}>
                {cupActionType !== CupActionTypeEnum.correction &&
                    <>
                        <div className="actions-button" onClick={toggleActionModal}>
                            <img src={icons.actionsButtonIcon}
                                 alt="actionsButtonIcon"
                            />
                        </div>
                        <ActionModal isOpen={isActionModalOpen}
                                     onClose={() => setIsActionModalOpen(false)}
                                     actions={[{
                                         title: 'Oznacz jako błąd',
                                         onClick: setAsError,
                                         className: 'delete'
                                     }]}
                                     x={actionModalPosition.x} y={actionModalPosition.y}/>
                    </>
                }
            </td>
            {isAdminMessageModalOpen && <AdminMessageModal
                isOpen={isAdminMessageModalOpen}
                setIsOpen={setIsAdminMessageModalOpen}
                predefinedTitle={adminMessageTitle}
                predefinedBody={adminBodyTitle}
            />}
        </tr>
    )
};

export default CupActionTableRow;