import Dashboard from "../components/dashboard/Dashboard";
import {useEffect} from "react";
import {getDashboardDataHandler} from "../handlers/merchantHandler";
import {useSelector} from "react-redux";
import {
    getChartInterval,
    getCurrentMerchantPoint, getCurrentTargetGroup,
    getDateRange, getIsMerchantSelected,
    getMerchant,
    getPreviousPeriod
} from "../redux/selectors/merchantSelector";
import {getTargetGroupsHandler} from "../handlers/targetGroupHandlers";


const DashboardContainer = () => {
    const dateRange = useSelector(getDateRange);
    const previousPeriod = useSelector(getPreviousPeriod);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const chartInterval = useSelector(getChartInterval);
    const targetGroup = useSelector(getCurrentTargetGroup);

    useEffect(() => {
        getDashboardDataHandler({dateRange, previousPeriod, chartInterval, targetGroup})
    }, [dateRange, previousPeriod, merchant, merchantPoint, isMerchantSelected, chartInterval, targetGroup]);

    useEffect(() => {
        getTargetGroupsHandler()
    }, []);

    return <Dashboard/>
};

export default DashboardContainer;