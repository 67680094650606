import {getMainServiceType} from "../../../services/utils";
import {cloudFrontUrl, getServiceTypeIcon} from "../../../../services/images";
import React from "react";
import {getServiceTypeTile} from "../../../../services/dictionaries/serviceTypesDict";
import {ItemOverviewProps} from "../../common/map/types";
import {AdminCupsMapPoint} from "../../../services/interfaces";


const AdminCupsMerchantPointOverview: React.FC<ItemOverviewProps<AdminCupsMapPoint>> = (merchantPoint) => {
    const mainServiceType = getMainServiceType(merchantPoint);
    let serviceTypeDetails = undefined;
    if (mainServiceType) {
        serviceTypeDetails = getServiceTypeTile(mainServiceType.name);
        if (!serviceTypeDetails)
            return <></>;
    } else
        return <></>;
    return (
        <div className="map-item-overview point" style={{cursor: "pointer"}}>
            <div className="left-section">
                <div className="logo">
                    <img src={merchantPoint.logo_image ?
                        cloudFrontUrl + merchantPoint.logo_image :
                        getServiceTypeIcon(mainServiceType.name, true)
                    }
                         alt=""
                         height={merchantPoint.logo_image ? 56 : serviceTypeDetails.iconHeight * 1.6}
                         width={merchantPoint.logo_image ? 56 : serviceTypeDetails.iconWidth * 1.6}
                    />
                </div>
                <div>
                    <div className="name-address-container">
                        <span className="name">
                            {merchantPoint.name}
                        </span>
                        <span className="address">
                            {merchantPoint.address}
                        </span>
                    </div>
                    <div className="bottom-container">
                        <div className="category">
                            <img src={getServiceTypeIcon(mainServiceType.name, true)} alt=""
                                 height={serviceTypeDetails.iconHeight / 2}
                                 width={serviceTypeDetails.iconWidth / 2}
                            />
                            <span className="category-label">{serviceTypeDetails.label}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-section">
                <span className="chevron right"/>
            </div>

        </div>
    )
};

export default AdminCupsMerchantPointOverview