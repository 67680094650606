import request, {GET} from "../../http/request";
import {handleError} from "../../http/handleError";

export const getAdminCupsMapPoints = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `cups/map/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getAdminCupsTable = async (urlParams: string) => {
    try {
        const {data} = await request(GET, `cups/table/?${urlParams}`, {}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};