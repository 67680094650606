import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import Button from "../common/Button";
import {icons} from "../../services/images";
import {OpeningHours} from "../../services/interfaces";
import ActionModal from "../modals/ActionModal";
import {omit} from "lodash";
import {DatePicker} from "antd";
import type {Moment} from 'moment';
import moment from "moment";
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import {RangePickerTimeProps} from "antd/es/time-picker";
import {isOpeningHoursFilled} from "../../services/helpers";
import {DayOfWeekMapping} from "../../services/dictionaries/enums";
import {sortByDayOfWeek} from "../../services/dates";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    openingHours: OpeningHours[];
    setOpeningHours: (openingHours: OpeningHours[]) => void;
}


// To replace Day.js with Moment.js in antd timepicker
const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

export interface TimePickerProps extends Omit<RangePickerTimeProps<Moment>, 'picker'> {
}

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
    <MyDatePicker.RangePicker {...props} picker="time" mode={undefined} ref={ref}/>
));

TimePicker.displayName = 'TimePicker';

const OpeningHoursModal = ({isOpen, setIsOpen, openingHours, setOpeningHours}: Props) => {
    const [selectedDayOpeningHours, setSelectedDayOpeningHours] = useState<OpeningHours | null>(null);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const dayItemRefs = useRef<(HTMLLIElement | null)[]>([]);
    const [modalOpeningHours, setModalOpeningHours] = useState<OpeningHours[]>([]);
    const format = "HH:mm";

    useEffect(() => {
        setModalOpeningHours(sortByDayOfWeek(openingHours));
    }, [isOpen, openingHours]);

    const toggleOpeningHoursModal = () => {
        setIsOpen(!isOpen);
        setIsActionModalOpen(false);
    };

    const toggleActionModal = (day: OpeningHours, index: number) => {
        setSelectedDayOpeningHours(day);
        const dayItem = dayItemRefs.current[index];
        if (dayItem) {
            const {right, bottom} = dayItem.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const applyToAll = () => {
        if (selectedDayOpeningHours) {
            const changedModalOpeningHours = modalOpeningHours.map(modalDayOpeningHours => ({
                    ...modalDayOpeningHours,
                    ...omit(selectedDayOpeningHours, ['id', 'day_of_week'])
                })
            );
            setModalOpeningHours(changedModalOpeningHours)
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const setOpeningTime = (changedDayOpeningHours: OpeningHours, openTime: Moment | null, closeTime: Moment | null) => {
        const formattedOpenTime = openTime ? openTime.format(format) : '';
        const formattedCloseTime = closeTime ? closeTime.format(format) : '';
        const changedModalOpeningHours = modalOpeningHours.map(modalDayOpeningHours =>
            modalDayOpeningHours.day_of_week === changedDayOpeningHours.day_of_week ?
                {
                    ...modalDayOpeningHours,
                    open_time: formattedOpenTime,
                    close_time: formattedCloseTime,
                    is_closed: false
                } :
                modalDayOpeningHours
        );
        setModalOpeningHours(changedModalOpeningHours)
    };

    const setSelectedDayAsClosed = () => {
        if (selectedDayOpeningHours) {
            const changedModalOpeningHours = modalOpeningHours.map(modalDayOpeningHours =>
                modalDayOpeningHours.day_of_week === selectedDayOpeningHours.day_of_week ?
                    {...modalDayOpeningHours, is_closed: true} :
                    modalDayOpeningHours
            );
            setModalOpeningHours(changedModalOpeningHours)
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const setDayAsOpen = (changedDayOpeningHours: OpeningHours) => {
        const changedModalOpeningHours = modalOpeningHours.map(modalDayOpeningHours =>
            modalDayOpeningHours.day_of_week === changedDayOpeningHours.day_of_week ?
                {...modalDayOpeningHours, is_closed: false} :
                modalDayOpeningHours
        );
        setModalOpeningHours(changedModalOpeningHours)
    };

    const onConfirm = () => {
        setOpeningHours(modalOpeningHours);
        setIsOpen(false);
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleOpeningHoursModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content opening-hours-modal"
        >
            <div className="modal-header">
                <h5>Ustal godziny otwarcia</h5>
                <div className="modal-close" onClick={toggleOpeningHoursModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ul className="modal-list">
                    {
                        modalOpeningHours.map((dayOpeningHours, index) => (
                            <li className="modal-list-item"
                                key={`Terminal${index}`}
                                ref={(ref) => (dayItemRefs.current[index] = ref)}
                            >
                                <span className="label">{DayOfWeekMapping[dayOpeningHours.day_of_week]}</span>
                                {!dayOpeningHours.is_closed ?
                                    <div className="item-row">
                                        <TimePicker
                                            placeholder={['Otwarcie', 'Zamknięcie']}
                                            format={format}
                                            minuteStep={5}
                                            value={[
                                                dayOpeningHours.open_time ? moment(dayOpeningHours.open_time, format) : undefined,
                                                dayOpeningHours.close_time ? moment(dayOpeningHours.close_time, format) : undefined]}
                                            onChange={(value) => setOpeningTime(
                                                dayOpeningHours,
                                                value ? value[0] : null,
                                                value ? value[1] : null
                                            )}
                                            needConfirm={true}

                                        />
                                        <div className="actions-button"
                                             onClick={() => toggleActionModal(dayOpeningHours, index)}>
                                            <img src={icons.actionsButtonIcon}
                                                 alt="actionsButtonIcon"
                                            />
                                        </div>
                                    </div> :
                                    <div className="item-row closed">
                                        <p>Zamknięte</p>
                                        <div className="actions-button"
                                             onClick={() => setDayAsOpen(dayOpeningHours)}>
                                            <img src={icons.penActiveIcon}
                                                 alt="editButtonIcon"
                                            />
                                        </div>
                                    </div>
                                }
                            </li>
                        ))
                    }
                </ul>
                <Button label="Potwierdź" onClick={onConfirm} fullWidth disabled={!isOpeningHoursFilled(modalOpeningHours)}/>
            </div>
            <div className="modal-footer"/>
            <ActionModal isOpen={isActionModalOpen}
                         onClose={() => setIsActionModalOpen(false)}
                         actions={[
                             {
                                 title: 'Zastosuj do wszystkich',
                                 onClick: applyToAll,
                                 className: 'blue-primary'
                             },
                             {
                                 title: 'Oznacz jako zamknięte',
                                 onClick: setSelectedDayAsClosed,
                                 className: 'delete'
                             },
                         ]}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
        </Modal>
    );
};

export default OpeningHoursModal;

