import {useDispatch, useSelector} from "react-redux";
import {
    getBarChartSelected,
    getChartInterval,
    getCurrentDashboardData,
    getCurrentMerchantPoint,
    getDatePreset,
    getDateRange,
    getIsMerchantSelected,
    getMerchant,
    getPreviousDashboardData,
    getPreviousPeriod
} from "../../redux/selectors/merchantSelector";
import DashboardCardTile from "./DashboardCardTile";
import DashboardIncomeTile from "./DashboardIncomeTile";
import DashboardMiddleTile from "./DashboardMiddleTile";
import DateRangePicker from "../common/DateRangePicker";
import {
    setBarChartSelectedAction,
    setChartIntervalAction,
    setDatePresetAction,
    setDateRangeAction,
    setPreviousPeriodAction
} from "../../redux/merchant";
import {ChartInterval, DatePreset, DateRange} from "../../services/interfaces";
import {getHeaderTitle, getMerchantCupCount} from "../../services/helpers";
import NotificationPopup from "../common/NotificationPopup";
import PreviousPeriodSelect from "../common/PreviousPeriodSelect";
import React, {useState} from "react";
import TargetGroupButton from "../common/TargetGroupButton";
import {icons} from "../../services/images";
import ControlsModal from "../modals/ControlsModal";
import ChartIntervalSelect from "../common/chart/ChartIntervalSelect";
import DashboardCupTile from "./DashboardCupTile";

const Dashboard = () => {
    const [isControlsModalOpen, setIsControlsModalOpen] = useState<boolean>(false);
    const merchant = useSelector(getMerchant);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const currentDashboardData = useSelector(getCurrentDashboardData);
    const previousDashboardData = useSelector(getPreviousDashboardData);
    const dateRange: DateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const chartInterval: ChartInterval = useSelector(getChartInterval);
    const previousPeriod = useSelector(getPreviousPeriod);
    const barChartSelected = useSelector(getBarChartSelected);

    const dispatch = useDispatch();

    if (!merchant)
        return <div/>;

    return (
        <div className="dashboard-content with-sticky-bar">
            <NotificationPopup/>
            <div className="controls-sticky-bar">
                <div>
                    <TargetGroupButton showTargetGroupChanges/>
                </div>
                <div className="selectors-with-labels">
                    <ChartIntervalSelect chartInterval={chartInterval}
                                         onIntervalChange={(interval) => dispatch(setChartIntervalAction(interval))}/>
                    <PreviousPeriodSelect previousPeriod={previousPeriod}
                                          onChange={(period) => dispatch(setPreviousPeriodAction(period))}/>
                    <div className="date-picker-label-wrapper">
                        <span className="label-2">Zakres czasu: </span>
                        <DateRangePicker dateRange={dateRange}
                                         setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                         datePreset={datePreset}
                                         setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                        />
                    </div>
                </div>
            </div>
            <div className="controls-mobile-button" onClick={() => setIsControlsModalOpen(true)}>
                <img src={icons.cogIcon} alt=""/>
            </div>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Kokpit - {getHeaderTitle(isMerchantSelected, merchant, merchantPoint)}</h2>
                </div>
            </div>
            <div className="block-wrapper chart-block-wrapper">
                <DashboardCardTile merchantPoint={merchantPoint} merchantPoints={merchant.merchant_points}/>
                <DashboardIncomeTile income={currentDashboardData.income}
                                     prevIncome={previousDashboardData.income}
                                     incomeData={currentDashboardData?.income_data}
                                     chartInterval={chartInterval}
                                     barChartSelected={barChartSelected}
                                     setBarChartSelected={(selected) => dispatch(setBarChartSelectedAction(selected))}
                />
            </div>
            <div className="block-wrapper">
                <DashboardMiddleTile title="Transakcje Payprofit"
                                     value={currentDashboardData?.transaction_count}
                                     prevValue={previousDashboardData?.transaction_count}
                />
                <DashboardMiddleTile title="Powracający klienci"
                                     value={currentDashboardData?.returning_customer_count}
                                     prevValue={previousDashboardData?.returning_customer_count}
                />
                <DashboardMiddleTile title="Nowi klienci"
                                     value={currentDashboardData?.new_customer_count}
                                     prevValue={previousDashboardData?.new_customer_count}
                />
            </div>
            <div className="block-wrapper cups-block-wrapper">
                <DashboardCupTile title="Kubki na stanie" icon={icons.cupUsedIcon} showPrev={false}
                                  value={isMerchantSelected ? getMerchantCupCount(merchant) : merchantPoint.cup_count}/>
                <DashboardCupTile title="Liczba użyć" icon={icons.cupUsedIcon}
                                  value={isMerchantSelected ? getMerchantCupCount(merchant) : merchantPoint.cup_count}/>
                <DashboardCupTile title="Kupione kubki" icon={icons.cupCollectedIcon}
                                  value={isMerchantSelected ? getMerchantCupCount(merchant) : merchantPoint.cup_count}/>
                <DashboardCupTile title="Zwrócone kubki" icon={icons.cupReturnedIcon}
                                  value={isMerchantSelected ? getMerchantCupCount(merchant) : merchantPoint.cup_count}/>
            </div>
            <ControlsModal isOpen={isControlsModalOpen} setIsOpen={setIsControlsModalOpen}/>
        </div>
    )
};

export default Dashboard;