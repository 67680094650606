const formFieldsDict: { [key: string]: string } = {
    name: 'Nazwa punktu',
    address: 'Adres punktu',
    service_types: 'Rodzaj usługi',
    opening_hours: 'Godziny otwarcia',
    merchant_name: 'Nazwa sieci',
    merchant_street: 'Adres sieci - Ulica',
    merchant_zip_code: 'Adres sieci - Kod pocztowy',
    merchant_city: 'Adres sieci - Miasto',
    merchant_nip: 'NIP',
    merchant_return_period: 'Okres zwrotu',
    phone: 'Numer kontaktowy'
};

export default formFieldsDict