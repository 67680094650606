import {
    AffectedReservations,
    CategoryType,
    CreateCategoryItem,
    DownloadTableProps,
    EmployeeCalendar,
    EmployeeSchedule,
    GetTableProps,
    ImportCategoriesData, OrderDetails,
    OrderTableResponse,
    Product, ProductCategory, ReservationDetails,
    ReservationTableResponse,
    Service, ServiceCategory,
    UpdateCategoryItem
} from "../services/interfaces";
import {getCurrentMerchantPoint, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {store} from "../redux/store";
import {prepareB64FileToXLSX, prepareURLParamForDownloadTable, prepareURLParamForTable} from "../services/utils";
import {saveAs} from "file-saver";
import {
    cancelReservation, completeOrder,
    createCategory,
    createCategoryItem,
    deleteCategory,
    deleteCategoryItem,
    downloadCategories,
    downloadOrders,
    downloadReservations,
    getCategories,
    getCategoryOptions,
    getEmployeeCalendar,
    getEmployees,
    getEmployeeSchedules,
    getEmployeeVacations, getOrder,
    getOrderFilterOptions,
    getOrderTable, getReservation,
    getReservationFilterOptions, getReservationsAffectedByEmployeeScheduleUpdate,
    getReservationTable,
    importCategories,
    importCategoryExcel,
    moveCategory, readyOrder,
    updateCalendar,
    updateCategory,
    updateCategoryItem,
    updateEmployeeSchedule,
    updateVacation
} from "../http/booking";
import {
    setAffectedReservationsAction,
    setCategoriesAction,
    setCategoryImportErrorsAction,
    setCategoryOptionsAction,
    setEmployeesAction,
    setEmployeeSchedulesAction,
    setEmployeeVacationsAction, setOrderDetailsAction,
    setOrderFilterOptionsAction,
    setOrderTableDataAction,
    setReservationDetailsAction,
    setReservationFilterOptionsAction,
    setReservationTableDataAction
} from "../redux/booking";
import {
    setResponseNotificationAction,
    setResponseNotificationErrorAction,
    setResponseNotificationSavedAction,
    setScreenLoadingAction
} from "../redux/navigation";
import {isArray, isEmpty} from "lodash";
import {Dispatch, SetStateAction} from "react";
import getNotificationMessage from "../services/dictionaries/notification";


export const getReservationTableHandler = (props: GetTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    const urlParam = prepareURLParamForTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getReservationTable(merchantPoint.id, urlParam.toString()).then((data: ReservationTableResponse) => {
            store.dispatch(setReservationTableDataAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const downloadReservationTableHandler = (props: DownloadTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const urlParam = prepareURLParamForDownloadTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        downloadReservations(merchantPoint.id, urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, 'reservations.xlsx')
        }).catch(error => {
            console.error(error);
        })
    }
};

export const getReservationFilterOptionsHandler = () => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getReservationFilterOptions(merchantPoint.id).then(data => {
            store.dispatch(setReservationFilterOptionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};


export const getOrderTableHandler = (props: GetTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    const urlParam = prepareURLParamForTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getOrderTable(merchantPoint.id, urlParam.toString()).then((data: OrderTableResponse) => {
            store.dispatch(setOrderTableDataAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const downloadOrderTableHandler = (props: DownloadTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const urlParam = prepareURLParamForDownloadTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        downloadOrders(merchantPoint.id, urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, 'orders.xlsx')
        }).catch(error => {
            console.error(error);
        })
    }
};

export const getOrderFilterOptionsHandler = () => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getOrderFilterOptions(merchantPoint.id).then(data => {
            store.dispatch(setOrderFilterOptionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const getCategoriesHandler = (categoryType: CategoryType, callback?: () => void) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    store.dispatch(setScreenLoadingAction(true));
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getCategories(merchantPoint.id, categoryType).then((data: ServiceCategory[] | ProductCategory[]) => {
            store.dispatch(setCategoriesAction({categories: data, categoryType}));
            store.dispatch(setScreenLoadingAction(false));
            callback && callback();
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false))
        });
    }
};


export const createCategoryHandler = (categoryType: CategoryType, name: string, callback: () => void, errorCallback: (error: string) => void) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        createCategory(merchantPoint.id, categoryType, name).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            if (error.status === 409 && error.detail === 'category_already_exists') {
                errorCallback(error.detail)
            } else
                errorCallback('unknown_error');
            console.error(error);
        });
    }
};


export const updateCategoryHandler = (
    categoryType: CategoryType,
    name: string,
    categoryId: number,
    callback: () => void,
    errorCallback: (error: string) => void
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        updateCategory(merchantPoint.id, categoryType, name, categoryId).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            if (error.status === 409 && error.detail === 'category_already_exists') {
                errorCallback(error.detail)
            } else
                errorCallback('unknown_error');
            console.error(error);
        });
    }
};

export const deleteCategoryHandler = (
    categoryType: CategoryType,
    categoryId: number,
    callback: () => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        deleteCategory(merchantPoint.id, categoryType, categoryId).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            console.error(error);
        });
    }
};


export const moveCategoryHandler = (
    categoryType: CategoryType,
    categoryId: number,
    direction: "up" | "down",
    callback: () => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        moveCategory(merchantPoint.id, categoryType, categoryId, direction).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            console.error(error);
        });
    }
};

export const getCategoryOptionsHandler = (pointId: number, categoryType: CategoryType, callback: () => void) => {
    getCategoryOptions(pointId, categoryType).then(data => {
        store.dispatch(setCategoryOptionsAction(data));
        callback();
    }).catch(error => {
        console.error(error);
    });
};

export const importCategoriesHandler = (
    categoryType: CategoryType,
    data: ImportCategoriesData,
    callback: () => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        importCategories(merchantPoint.id, categoryType, data).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            callback();
            console.error(error);
        });
    }
};


export const createCategoryItemHandler = (categoryType: CategoryType, createData: CreateCategoryItem, callback: () => void, errorCallback: (error: string) => void) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        createCategoryItem(merchantPoint.id, categoryType, createData).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            if (error.status === 409 && (error.detail === 'product_already_exists' || error.detail === 'service_already_exists')) {
                errorCallback(error.detail)
            } else
                errorCallback('unknown_error');
            console.error(error);
        });
    }
};

export const updateCategoryItemHandler = (
    categoryType: CategoryType,
    updateData: UpdateCategoryItem,
    itemId: number,
    callback: () => void,
    errorCallback: (error: string) => void
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        updateCategoryItem(merchantPoint.id, categoryType, updateData, itemId).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback()
        }).catch(error => {
            if (error.status === 409 && (error.detail === 'product_already_exists' || error.detail === 'service_already_exists')) {
                errorCallback(error.detail)
            } else
                errorCallback('unknown_error');
            console.error(error);
        });
    }
};


export const deleteCategoryItemHandler = (
    categoryType: CategoryType,
    itemId: number,
    callback: () => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        deleteCategoryItem(merchantPoint.id, categoryType, itemId).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback();
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            callback();
            console.error(error);
        });
    }
};


export const activateCategoryItemHandler = (
    categoryType: CategoryType,
    item: Service | Product,
    callback: () => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        updateCategoryItem(merchantPoint.id, categoryType, {active: !item.active}, item.id).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback();
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            callback();
            console.error(error);
        });
    }
};


export const downloadCategoriesHandler = (categoryType: CategoryType) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        downloadCategories(merchantPoint.id, categoryType).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, `${categoryType}s.xlsx`)
        }).catch(error => {
            console.error(error);
        })
    }
};


export const importCategoryExcelHandler = (categoryType: CategoryType, file: string, callback: () => void) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        importCategoryExcel(merchantPoint.id, categoryType, file).then((data) => {
            const {items_created, items_updated} = data;
            store.dispatch(setResponseNotificationAction({
                status: "success",
                message: `Utworzono: ${items_created} i Zaktualizowano: ${items_updated} ${categoryType === 'product' ? 'Produktów' : 'Usług'}`
            }));
            callback();
        }).catch(error => {
            console.log('error in Handler', error);
            if (isArray(error.detail))
                store.dispatch(setCategoryImportErrorsAction(error.detail));
            else {
                store.dispatch(setResponseNotificationErrorAction());
                console.error(error);
            }
        });
    }
};


export const getEmployeeSchedulesHandler = () => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getEmployeeSchedules(merchantPoint.id).then(data => {
            store.dispatch(setEmployeeSchedulesAction(data));
        }).catch(error => {
            console.error(error);
        });
    }
};

export const getReservationsAffectedByEmployeeScheduleUpdateHandler = (
    employeeSchedule: EmployeeSchedule,
    affectedReservationCallback: () => void,
    noAffectedReservationCallback: () => void,
    errorCallback: (error: string) => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        const scheduleJson = JSON.stringify(employeeSchedule);
        const urlParam = new URLSearchParams();
        urlParam.append('schedule_json', scheduleJson);
        getReservationsAffectedByEmployeeScheduleUpdate(merchantPoint.id, employeeSchedule.id, urlParam.toString()).then((data: AffectedReservations) => {
            if (isEmpty(data.reservations_to_postpone) && isEmpty(data.reservations_to_cancel)) {
                updateEmployeeScheduleHandler(employeeSchedule, noAffectedReservationCallback, errorCallback)
            } else {
                affectedReservationCallback();
                store.dispatch(setAffectedReservationsAction(data));
            }
        }).catch(error => {
            console.error(error);
            errorCallback('unknown_error')
        });
    }
};


export const updateEmployeeScheduleHandler = (
    employeeSchedule: EmployeeSchedule,
    callback: () => void,
    errorCallback: (error: string) => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        updateEmployeeSchedule(merchantPoint.id, employeeSchedule).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback();
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            errorCallback('unknown_error');
            console.error(error);
        });
    }
};


export const getEmployeeVacationsHandler = () => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getEmployeeVacations(merchantPoint.id).then(data => {
            store.dispatch(setEmployeeVacationsAction(data));
        }).catch(error => {
            console.error(error);
        });
    }
};


export const updateVacationHandler = (
    employeeId: number,
    vacationDays: string[],
    callback: () => void,
    errorCallback: (error: string) => void,
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        updateVacation(merchantPoint.id, employeeId, vacationDays).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback();
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            errorCallback('unknown_error');
            console.error(error);
        });
    }
};

export const getEmployeesHandler = () => {
    getEmployees().then(data => {
        store.dispatch(setEmployeesAction(data));
    }).catch(error => {
        console.error(error);
    });
};

export const getEmployeeCalendarHandler = (employeeId: number, month: string, setEmployeeCalendar: Dispatch<SetStateAction<EmployeeCalendar | null>>) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getEmployeeCalendar(merchantPoint.id, employeeId, month).then(data => {
            setEmployeeCalendar((data));
        }).catch(error => {
            console.error(error);
        });
    }
};


export const updateCalendarHandler = ({
                                          employeeId, newTimeOffs, timeOffsToDelete, errorCallback, callback
                                      }: {
                                          employeeId: number,
                                          newTimeOffs: string[],
                                          timeOffsToDelete: number[],
                                          callback: () => void,
                                          errorCallback: (error: string) => void
                                      }
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        updateCalendar(merchantPoint.id, employeeId, newTimeOffs, timeOffsToDelete).then(data => {
            store.dispatch(setResponseNotificationSavedAction());
            callback();
        }).catch(error => {
            store.dispatch(setResponseNotificationErrorAction());
            errorCallback('unknown_error');
            console.error(error);
        });
    }
};


export const getOrderHandler = (orderId: number) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getOrder(merchantPoint.id, orderId).then((data: OrderDetails) => {
            store.dispatch(setOrderDetailsAction(data));
        }).catch(error => {
            console.error(error);
        });
    }
};

export const getReservationHandler = (reservationId: number) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getReservation(merchantPoint.id, reservationId).then((data: ReservationDetails) => {
            store.dispatch(setReservationDetailsAction(data));
        }).catch(error => {
            console.error(error);
        });
    }
};

export const cancelReservationHandler = ({reservationId, callback, errorCallback}: {
    reservationId: number,
    callback: () => void,
    errorCallback?: () => void,
}) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        cancelReservation(merchantPoint.id, reservationId).then(_ => {
            callback();
            store.dispatch(setResponseNotificationAction({status: "success", message: getNotificationMessage("reservation_canceled")}))
        }).catch(error => {
            console.error(error);
            store.dispatch(setResponseNotificationErrorAction());
            errorCallback && errorCallback();
        });
    }
};

//
// export const cancelOrderHandler = ({orderId, callback, errorCallback}: {
//     orderId: number,
//     callback: () => void,
//     errorCallback?: () => void,
// }) => {
//     const isMerchantSelected = getIsMerchantSelected(store.getState());
//     const merchantPoint = getCurrentMerchantPoint(store.getState());
//
//     if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
//         cancelOrder(merchantPoint.id, orderId).then(_ => {
//             callback();
//             store.dispatch(setResponseNotificationAction({status: "success", message: getNotificationMessage("order_canceled")}))
//         }).catch(error => {
//             console.error(error);
//             store.dispatch(setResponseNotificationErrorAction());
//             errorCallback && errorCallback();
//         });
//     }
// };

export const readyOrderHandler = ({orderId, callback, errorCallback}: {
    orderId: number,
    callback: () => void,
    errorCallback?: () => void,
}) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        readyOrder(merchantPoint.id, orderId).then(_ => {
            callback();
            store.dispatch(setResponseNotificationAction({status: "success", message: getNotificationMessage("order_ready")}))
        }).catch(error => {
            console.error(error);
            store.dispatch(setResponseNotificationErrorAction());
            errorCallback && errorCallback();
        });
    }
};


export const completeOrderHandler = ({orderId, callback, errorCallback}: {
    orderId: number,
    callback: () => void,
    errorCallback?: () => void,
}) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        completeOrder(merchantPoint.id, orderId).then(_ => {
            callback();
            store.dispatch(setResponseNotificationAction({status: "success", message: getNotificationMessage("order_completed")}))
        }).catch(error => {
            console.error(error);
            store.dispatch(setResponseNotificationErrorAction());
            errorCallback && errorCallback();
        });
    }
};
