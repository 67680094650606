import React, {useEffect, useState} from 'react';
import MapView from "../../common/map/MapView";
import MapPanel from "../../common/map/MapPanel";
import {AdminCupsMapPoint} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getAdminDateRange, getAdminGlobalFilters} from "../../../redux/selectors/adminViewSettingsSelectors";
import AdminCupsMerchantPointOverview from "./AdminCupsMerchantPointOverview";
import AdminCupsMapInfoWindow from "./AdminCupsMapInfoWindow";
import AdminCupsMapMarker from "./AdminCupsMapMarker";
import {getAdminCupsMapPoints} from "../../../redux/selectors/adminCupsSelectors";
import {getAdminCupsMapHandler} from "../../../handlers/cupsHandlers";
import AdminCupsMapFiltersModal from "./AdminCupsMapFiltersModal";


const AdminCupsMap = () => {
    const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [filterString, setFilterString] = useState<string>('');
    const dateRange = useSelector(getAdminDateRange);
    const globalFilters = useSelector(getAdminGlobalFilters);
    const filteredItems: AdminCupsMapPoint[] = useSelector(getAdminCupsMapPoints);

    useEffect(() => {
        getAdminCupsMapHandler(dateRange, globalFilters, query, filterString)
    }, [dateRange, globalFilters, query, filterString]);

    return (
        <div className="map-container">
            <MapView<AdminCupsMapPoint> items={filteredItems}
                                        MarkerComponent={AdminCupsMapMarker}
                                        InfoWindowContent={AdminCupsMapInfoWindow}
            />
            <MapPanel<AdminCupsMapPoint> title="Ekokubki"
                                              items={filteredItems}
                                              ItemOverviewComponent={AdminCupsMerchantPointOverview}
                                              setQuery={setQuery}
                                              setFiltersModalOpen={setFiltersModalOpen}/>
            <AdminCupsMapFiltersModal open={filtersModalOpen} setOpen={setFiltersModalOpen}
                                      setFilters={setFilterString}/>
        </div>
    );
};

export default AdminCupsMap;
