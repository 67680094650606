import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminCupsMapPoint, AdminCupsTableResponse, CupsMerchantSummary,} from "../services/interfaces";

export interface AdminCupsState {
    mapPoints: AdminCupsMapPoint[]
    table: {
        merchants: CupsMerchantSummary[],
        maxPage: number,
        merchantsCount: number,
        pageSize: number
    }

}


const DEFAULT_STATE: AdminCupsState = {
    mapPoints: [],
    table: {
        merchants: [],
        maxPage: 0,
        pageSize: 0,
        merchantsCount: 0
    }
};

const adminCupsSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'adminCups',
    reducers: {
        resetAdminCupsState: (state) => {
            return {...state, ...DEFAULT_STATE}
        },

        setMerchantPoints: (state, action: PayloadAction<AdminCupsMapPoint[]>) => {
            return {
                ...state,
                mapPoints: action.payload
            }
        },
        setTable: (state, action: PayloadAction<AdminCupsTableResponse>) => {
            return {
                ...state,
                table: {
                    merchants: action.payload.merchants,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    merchantsCount: action.payload.merchants_count
                },
            }
        },
    }
});

export default adminCupsSlice.reducer;


export const setAdminCupsMerchantPointsAction = adminCupsSlice.actions.setMerchantPoints;
export const setAdminCupsTableAction = adminCupsSlice.actions.setTable;

