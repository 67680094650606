import {combineReducers} from "@reduxjs/toolkit";
import adminDashboardReducer from './adminDashboard';
import adminFinanceReducer from './adminFinance';
import adminViewSettingsReducer from './adminViewSettings';
import adminMerchantsReducer from './adminMerchants';
import adminUsersReducer from './adminUsers';
import adminCupsReducer from './adminCups';


const adminRootReducer = combineReducers({
    dashboard: adminDashboardReducer,
    finance: adminFinanceReducer,
    merchants: adminMerchantsReducer,
    users: adminUsersReducer,
    cups: adminCupsReducer,
    viewSettings: adminViewSettingsReducer,
});

export default adminRootReducer