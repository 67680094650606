import {FilterModalProps, MultiSelectOption} from "../../../../services/interfaces";
import {AdminCupsTableFilterOptions} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getAdminCupsTableFilterOptions} from "../../../redux/selectors/adminViewSettingsSelectors";
import React, {useEffect, useState} from "react";
import {getAdminCupsTableFilterOptionsHandler} from "../../../handlers/filterOptionsHandlers";
import Modal from "react-modal";
import {icons} from "../../../../services/images";
import FiltersSlider from "../../../../components/modals/filters/FiltersSlider";
import Button from "../../../../components/common/Button";
import FiltersDropdown from "../../../../components/modals/filters/FiltersDropdown";


const AdminCupsTableFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: AdminCupsTableFilterOptions | null = useSelector(getAdminCupsTableFilterOptions);
    const [columns, setColumns] = useState<string[]>([]);
    const [cupActionCountSlider, setCupActionCountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.cup_action.min_count, filterOptions.cup_action.max_count] : null);

    const columnsOptions: MultiSelectOption[] = [
        {value: 'Nazwa', children: 'name'},
        {value: 'Branża', children: 'service_type'},
        {value: 'Liczba akcji', children: 'cup_actions_count'},
        {value: 'Stan magazynowy', children: 'cup_count'},
    ];

    useEffect(() => {
        getAdminCupsTableFilterOptionsHandler();
    }, []);

    useEffect(() => {
        if (filterOptions && (cupActionCountSlider === null)) {
            setCupActionCountSlider([filterOptions.cup_action.min_count, filterOptions.cup_action.max_count])
        }
    }, [filterOptions]);


    const clearFilters = () => {
        setColumns([]);
        setCupActionCountSlider([filterOptions?.cup_action.min_count || 0, filterOptions?.cup_action.max_count || 100]);
    };

    const applyFilters = () => {
        if (!cupActionCountSlider) {
            return;
        }
        const urlFilters = '' +
            '&columns=' + columns.join(',') +
            '&cup_action_count=' + cupActionCountSlider.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };


    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
                <FiltersSlider
                    label="Liczba akcji kubkowych"
                    min={filterOptions?.cup_action.min_count || 0}
                    max={filterOptions?.cup_action.max_count || 100}
                    value={cupActionCountSlider}
                    onChange={setCupActionCountSlider}
                    wholeNumber
                />
            </div>
            <div className="modal-footer">
                {filterOptions &&
                    <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default AdminCupsTableFiltersModal