export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export enum DayOfWeekMapping {
    MONDAY = 'Poniedziałek',
    TUESDAY = 'Wtorek',
    WEDNESDAY = 'Środa',
    THURSDAY = 'Czwartek',
    FRIDAY = 'Piątek',
    SATURDAY = 'Sobota',
    SUNDAY = 'Niedziela',
}

export enum TransactionStatus {
    receivables = 'W toku',
    due = 'Do opłacenia',
    wallet = 'Opłacone',
    queue = 'W kolejce',
    withdrawn = 'Wypłacone',
    rejected = 'Odrzucone',
    refund = 'Zwrot'
}

export enum CupActionTypeEnum {
    collected = 'Odbiór',
    returned = 'Zwrot',
    used = 'Użycie',
    exchanged = 'Wymiana',
    collected_and_used = 'Odbiór + Użycie',
    exchanged_and_used = 'Wymiana + Użycie',
    added = 'Dodanie',
    removed = 'Rozchód',
    correction = 'Korekta'
}

export enum GenderEnum {
    female = 'Kobieta',
    male = 'Mężczyzna',
    unknown = 'Inna',
}

export enum TargetGroupRangeKeyEnum {
    amount_ranges = 'Kwota pojedynczych zakupów',
    amount_sum_ranges = 'Łączna kwota zakupów',
    transactions_ranges = 'Liczba transakcji',
    age_ranges = 'Wiek',
}

export enum OfferTypeMapping {
    'gratis' = 'Gratis',
    'contest' = 'Konkurs',
    'discount' = 'Rabat',
    'event' = 'Wydarzenie',
    'news' = 'Nowości',
    'extra_cashback' = 'Dodatkowy cashback',
}

export enum OfferUsageMapping {
     'one_time' = 'Jednorazowa',
     'unlimited' = 'Nielimitowana',
}


export enum ReservationStatusEnum {
    placed = 'Zarezerwowana',
    paid = 'Opłacona',
    canceled = 'Anulowana',
    completed = 'Wykonana',
    ready = ''
}

export enum OrderStatusEnum {
    placed = 'Złożone',
    paid = 'Opłacone',
    ready = 'Gotowe',
    canceled = 'Anulowane',
    completed = 'Odebrane',
}

export enum ProductUnitEnum {
    piece = 'Sztuka',
    kilogram = 'Kilogram',
}

export enum ProductUnitShortEnum {
    piece = 'szt.',
    kilogram = 'kg',
}