import {CupsMerchantPointSummary} from "../../../services/interfaces";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import {translateServiceType} from "../../../../services/dictionaries/serviceTypesDict";

const AdminCupsMerchantPointRow = ({merchantPoint, excludedColumns}: {
    merchantPoint: CupsMerchantPointSummary,
    excludedColumns: string[]
}) => {
    return (
        <tr className="inner-row">
            {!excludedColumns.includes('name') &&
                <td className="client-id-cell">{merchantPoint.name}</td>}
            {!excludedColumns.includes('service_type') && <td>{translateServiceType(merchantPoint.service_type)}</td>}
            {!excludedColumns.includes('points_count') && <td>--</td>}
            {!excludedColumns.includes('cup_actions_count') &&
                <td className="cell-digit">{formatNumber(merchantPoint.cup_actions_count, WHOLE_NUMBER_FORMAT)}</td>}
            {!excludedColumns.includes('cup_count') &&
                <td className="cell-digit">{formatNumber(merchantPoint.cup_count, WHOLE_NUMBER_FORMAT)}</td>}
        </tr>
    )
};

export default AdminCupsMerchantPointRow;