import ChangePill from "../common/ChangePill";
import TimeRangeLabel from "../common/TimeRangeLabel";


interface Props {
    title: string;
    icon: string;
    showPrev?: boolean;
    value?: number
    prevValue?: number
}

const DashboardCupTile = ({title, icon, showPrev=true, value, prevValue}: Props) => {
    return (
        <div className="block block-1-4">
            <div className="value-section">
                <h4>{title}</h4>
                <div className="value-wrapper">
                    <div className="value-icon-wrapper">
                        <span className="standard-value">{value || 0}</span>
                        <img src={icon} alt="" className="cup-icon"/>
                    </div>
                    {showPrev && <ChangePill value={value} prevValue={prevValue}/>}
                    {showPrev && <TimeRangeLabel/>}
                </div>
            </div>
        </div>
    )
};

export default DashboardCupTile;