import moment from "moment";
import _ from "lodash";
import {
    ChartInterval,
    DateRange,
    DownloadTableProps,
    GetDashboardDataProps,
    GetTableProps,
    MerchantPointSimple,
    OfferDay,
    PreviousPeriod
} from "./interfaces";

export const getUTCTimestamp = (): number => {
    return moment.utc().valueOf() / 1000;
};

export const isTokenExpired = (expirationDate: string): boolean => {
    // Force generating a new pair of tokens 5 seconds before the expiration date
    const expirationOffset: number = 5;
    return +expirationDate - expirationOffset < getUTCTimestamp();
};

// Function to parse the query string into an object
export const parseQueryString = (query: string): { [key: string]: string } => {
    return _.fromPairs(query.split('&').map(param => param.split('=')));
};

export const separateAccountNumber = (accountNumber: string): string => {
    accountNumber = accountNumber.substring(2);

    const chunks = [accountNumber.substring(0, 2)];
    for (let i = 2; i < accountNumber.length; i += 4) {
        chunks.push(accountNumber.substring(i, i + 4));
    }
    return chunks.join(" ");
};

export const getAddressWithoutCountry = (merchantPoint: MerchantPointSimple) => {
    let address = null;
    if (merchantPoint.address) {
        const merchantAddress = merchantPoint.address;
        address = merchantAddress.split(', ').slice(0, -1).join(', ')
    }
    return address;
};

const prepareB64File = (base64String: string): Uint8Array => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
}

export const prepareB64FileToXLSX = (base64String: string): Blob => {
    const byteArray = prepareB64File(base64String);
    return new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
};

export const prepareB64FileToText = (base64String: string): Blob => {
    const byteArray = prepareB64File(base64String);
    return new Blob([byteArray], {type: 'text/plain'})
}

export const prepareB64FileToPDF = (base64String: string): Blob => {
    const byteArray = prepareB64File(base64String);
    return new Blob([byteArray], {type: 'application/pdf'})
}

export const mapOfferDaysToString = (days: OfferDay[]): string => {
    if (!days) return "";

    const daysArray = days;

    const dayMapping: Record<string, string> = {
        WEEK: 'Tygodniu',
        WORK_WEEK: 'Dni robocze',
        MONDAY: 'Poniedziałek',
        TUESDAY: 'Wtorek',
        WEDNESDAY: 'Środę',
        THURSDAY: 'Czwartek',
        FRIDAY: 'Piątek',
        SATURDAY: 'Sobotę',
        SUNDAY: 'Niedzielę'
    };

    const containsAllDays = (arr: string[], daysToCheck: string[]): boolean => {
        return daysToCheck.every(day => arr.includes(day));
    };

    let uniqueDays = daysArray
        .map(day => day.days_offer)
        .filter((value, index, self) => self.indexOf(value) === index);

    const workWeekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
    const weekendDays = ['SATURDAY', 'SUNDAY'];
    const allWeekDays = [...workWeekDays, ...weekendDays];

    if (containsAllDays(uniqueDays, allWeekDays)) {
        uniqueDays = uniqueDays.filter(day => !allWeekDays.includes(day));
        uniqueDays.push('WEEK');
    } else if (containsAllDays(uniqueDays, workWeekDays)) {
        uniqueDays = uniqueDays.filter(day => !workWeekDays.includes(day));
        uniqueDays.push('WORK_WEEK');
    }

    return uniqueDays
        .map(day => dayMapping[day])
        .filter(day => day !== undefined)
        .join(', ');
};

export const showCustomersNumber = (value: number): string => {
    if (value === 0 || value >= 5) {
        return `${value} osób`;
    } else if (value === 1) {
        return `${value} osoba`;
    } else if (value > 1 && value < 5) {
        return `${value} osoby`;
    } else {
        return `${value} osób`;
    }
};


export const prepareURLParamForDashboardData = ({
                                                    dateRange,
                                                    previousPeriod,
                                                    chartInterval,
                                                    targetGroup
                                                }: GetDashboardDataProps): string => {
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);

    if (targetGroup && targetGroup.id)
        urlParam.append('target_group_id', targetGroup.id.toString());

    return urlParam.toString()
};


export const prepareURLParamForTable = ({page, query, sort, order, filters, pageSize}: GetTableProps) => {
    const urlParam = new URLSearchParams();
    urlParam.append('page', page.toString());
    if (query !== '')
        urlParam.append('q', query);
    if (sort !== '' && order !== null)
        urlParam.append('sort', `${sort},${order}`);
    if (filters !== '')
        urlParam.append('filters', filters);
    if (pageSize !== 10)
        urlParam.append('page_size', pageSize.toString());
    return urlParam
};

export const prepareURLParamForDownloadTable = ({query, sort, order, filters}: DownloadTableProps) => {
    const urlParam = new URLSearchParams();
    if (query !== '')
        urlParam.append('q', query);
    if (sort !== '' && order !== null)
        urlParam.append('sort', `${sort},${order}`);
    if (filters !== '')
        urlParam.append('filters', filters);
    return urlParam
};