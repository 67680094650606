import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChartInterval, DatePreset, DateRange, PreviousPeriod} from "../../services/interfaces";
import {DEFAULT_END_DATE, DEFAULT_PRESET, DEFAULT_START_DATE} from "../../services/dates";
import {
    AdminCupsTableFilterOptions,
    AdminDashboardMapFilterOptions,
    AdminGlobalFilters,
    AdminMerchantSimple,
    AdminMerchantTransactionsFilterOptions, BankIdentification,
} from "../services/interfaces";

interface AdminViewSettingsState {
    dateRange: DateRange,
    datePreset: DatePreset | null,
    chartInterval: ChartInterval,
    firstTransactionDate: string | null,
    previousPeriod: PreviousPeriod,
    barChartSelected: boolean,
    globalFilters: AdminGlobalFilters,
    filterOptions: {
        dashboardMap: AdminDashboardMapFilterOptions | null
        merchantTransactions: AdminMerchantTransactionsFilterOptions | null
        merchants: AdminMerchantSimple[]
        banks: BankIdentification[]
        cupsTable: AdminCupsTableFilterOptions | null
    }
}


const DEFAULT_STATE: AdminViewSettingsState = {
    dateRange: {
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE
    },
    datePreset: DEFAULT_PRESET,
    chartInterval: "DAY",
    firstTransactionDate: null,
    previousPeriod: "period",
    barChartSelected: true,
    globalFilters: {
        serviceTypes: [],
        merchantPoints: []
    },
    filterOptions: {
        dashboardMap: null,
        merchantTransactions: null,
        merchants: [],
        banks: [],
        cupsTable: null,
    }

};

const adminViewSettingsSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'adminViewSettings',
    reducers: {
        setDateRange: (state, action: PayloadAction<DateRange>) => {
            return {
                ...state,
                dateRange: action.payload
            }
        },
        setDatePreset: (state, action: PayloadAction<DatePreset | null>) => {
            return {
                ...state,
                datePreset: action.payload
            }
        },
        setChartInterval: (state, action: PayloadAction<ChartInterval>) => {
            return {
                ...state,
                chartInterval: action.payload
            }
        },
        setFirstTransactionDate: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                firstTransactionDate: action.payload,
            }
        },
        setPreviousPeriod: (state, action: PayloadAction<PreviousPeriod>) => {
            return {
                ...state,
                previousPeriod: action.payload
            }
        },
        setBarChartSelected: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                barChartSelected: action.payload
            }
        },
        setGlobalFilters: (state, action: PayloadAction<AdminGlobalFilters>) => {
            return {
                ...state,
                globalFilters: action.payload
            }
        },
        setMerchantsFilterOptions: (state, action: PayloadAction<AdminMerchantSimple[]>) => {
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    merchants: action.payload
                }
            }
        },
        setDashboardMapFilterOptions: (state, action: PayloadAction<AdminDashboardMapFilterOptions>) => {
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    dashboardMap: action.payload
                }
            }
        },
        setMerchantTransactionsFilterOptions: (state, action: PayloadAction<AdminMerchantTransactionsFilterOptions>) => {
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    merchantTransactions: action.payload
                }
            }
        },
        setBanksFilterOptions: (state, action: PayloadAction<BankIdentification[]>) => {
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    banks: action.payload
                }
            }
        },
        setCupsTableFilterOptions: (state, action: PayloadAction<AdminCupsTableFilterOptions>) => {
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    cupsTable: action.payload
                }
            }
        },

    }
});

export default adminViewSettingsSlice.reducer;


export const setAdminDateRangeAction = adminViewSettingsSlice.actions.setDateRange;
export const setAdminDatePresetAction = adminViewSettingsSlice.actions.setDatePreset;
export const setAdminChartIntervalAction = adminViewSettingsSlice.actions.setChartInterval;
export const setAdminPreviousPeriodAction = adminViewSettingsSlice.actions.setPreviousPeriod;
export const setAdminBarChartSelectedAction = adminViewSettingsSlice.actions.setBarChartSelected;
export const setAdminFirstTransactionDateAction = adminViewSettingsSlice.actions.setFirstTransactionDate;
export const setAdminGlobalFiltersAction = adminViewSettingsSlice.actions.setGlobalFilters;
export const setAdminMerchantsFilterOptionsAction = adminViewSettingsSlice.actions.setMerchantsFilterOptions;
export const setAdminDashboardMapFilterOptionsAction = adminViewSettingsSlice.actions.setDashboardMapFilterOptions;
export const setAdminMerchantTransactionsFilterOptionsAction = adminViewSettingsSlice.actions.setMerchantTransactionsFilterOptions;
export const setAdminBanksFilterOptions = adminViewSettingsSlice.actions.setBanksFilterOptions;
export const setAdminCupsTableFilterOptionsAction = adminViewSettingsSlice.actions.setCupsTableFilterOptions;
