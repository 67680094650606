import React, {useCallback, useEffect, useState} from "react";
import {icons} from "../../services/images";
import Input from "../common/Input";
import NIPSearch from "./NIPSearch";
import {
    Merchant,
    MerchantPoint,
    MerchantPointSimple,
    MerchantPointUpdateData,
    MerchantUpdateData
} from "../../services/interfaces";
import AddressGoogleSearch from "./MerchantPointGoogleMapsSearch";
import TerminalManagementModal from "./TerminalManagementModal";
import OpeningHoursSection from "./OpeningHoursSection";
import AddImage from "./AddImage";
import ColorPicker from "./ColorPicker";
import CashbackLevelSelect from "./CashbackLevelSelect";
import ServiceTypesSelect from "./ServiceTypesSelect";
import Switch from "../common/Switch";
import Button from "../common/Button";
import {forEach, isEmpty, isEqual, omit, some} from "lodash";
import {isOpeningHoursFilled} from "../../services/helpers";
import ApplyChangesModal from "./ApplyChangesModal";
import {
    createMerchantPointAndUpdateMerchantHandler,
    getMasterCashbackLevelsHandler,
    updateMerchantPointHandler,
} from "../../handlers/merchantHandler";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentMerchantPoint, getMerchant} from "../../redux/selectors/merchantSelector";
import {MERCHANT_POINT_DEFAULT_STATE} from "../../redux/merchant";
import ConfirmChangeModal from "../modals/ConfirmChangeModal";
import CardPreview from "./CardPreview";
import {Tooltip} from "react-tooltip";
import {setShouldBlockNavigation} from "../../redux/navigation";
import NotificationPopup from "../common/NotificationPopup";
import formFieldsDict from "../../services/dictionaries/formFieldsDict";
import CountrySelect from "../common/CountrySelect";
import {AsYouType, CountryCode, isValidPhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import SaveResponseModal from "./SaveResponseModal";

const MERCHANT_DEFAULT_STATE: Merchant = {
    name: '',
    nip: '',
    return_period: 0,
    street: '',
    zip_code: '',
    city: '',
    merchant_points: [],
    deposit: 0,
    blocked_funds: 0,
    deposit_requirement: 0
};

interface Props {
    isMerchantPointAdded: boolean
}

interface PhoneDataInterface {
    number: string;
    countryCode: string;
}

const MerchantPointForm = ({isMerchantPointAdded}: Props) => {
    const [merchantFormData, setMerchantFormData] = useState<Merchant>(MERCHANT_DEFAULT_STATE);
    const [merchantPointFormData, setMerchantPointFormData] = useState<MerchantPoint>(MERCHANT_POINT_DEFAULT_STATE);
    const [isTerminalModalOpen, setIsTerminalModalOpen] = useState(false);
    const [isApplyChangesModalOpen, setIsApplyChangesModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [phoneData, setPhoneData] = useState<PhoneDataInterface>({
        number: '',
        countryCode: 'PL',
    });
    const dispatch = useDispatch();
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const isMerchantConfigured = !!merchant && merchant.nip !== null;
    const isCashbackChanged = merchantPoint.cashback !== merchantPointFormData.cashback;

    useEffect(() => {
        if (merchant)
            setMerchantFormData(merchant);
        if (merchant && merchantPoint) {
            setMerchantPointFormData(merchantPoint);
            if (merchantPoint.phone) {
                const parsedPhone = parsePhoneNumber(merchantPoint.phone as string);
                setPhoneData({
                    number: parsedPhone.nationalNumber,
                    countryCode: parsedPhone.country as string
                });
            } else {
                setPhoneData({
                    number: '',
                    countryCode: "PL"
                });
            }
        }

    }, [merchant, merchantPoint, isMerchantPointAdded]);

    const isEdited = useCallback(() => {
        const isMerchantPointFormEdited = !isEqual(merchantPoint, merchantPointFormData);
        const isMerchantFormEdited = !isEqual(merchant, merchantFormData);
        return isMerchantFormEdited || isMerchantPointFormEdited;
    }, [merchantPoint, merchantPointFormData, merchantFormData, merchant]);

    useEffect(() => {
        dispatch(setShouldBlockNavigation(isEdited()))
    }, [dispatch, isEdited]);


    useEffect(() => {
        getMasterCashbackLevelsHandler();
    }, []);

    useEffect(() => {
        if (isValidPhoneNumber(phoneData.number, phoneData.countryCode as CountryCode || 'PL'))
            changeMerchantPointFormData('phone', parsePhoneNumber(phoneData.number, phoneData.countryCode as CountryCode).number)
    }, [phoneData]);

    const changeMerchantPointFormData = (field: string, value: any) =>
        setMerchantPointFormData(prevState => ({
            ...prevState,
            [field]: value
        }));

    const onResetChangesClick = () => {
        if (merchant)
            setMerchantFormData(merchant);
        setMerchantPointFormData(merchantPoint);
        if (merchantPoint.phone) {
            const parsedPhone = parsePhoneNumber(merchantPoint.phone as string);
            setPhoneData({
                number: parsedPhone.nationalNumber,
                countryCode: parsedPhone.country as string
            });
        } else {
            setPhoneData({
                number: '',
                countryCode: "PL"
            });
        }
    };

    const prepareMerchantPointUpdateData = (merchantPointsToUpdate?: number[]) => {
        let updateData: MerchantPointUpdateData = {...MERCHANT_POINT_DEFAULT_STATE, merchant_points_to_update: []};
        forEach(omit(merchantPointFormData, 'id'), ((_, key: string) => {
            if (isEqual(merchantPoint[key], merchantPointFormData[key])) {
                updateData[key] = null
            } else
                updateData[key] = merchantPointFormData[key]
        }));
        if (merchantPointsToUpdate)
            updateData.merchant_points_to_update = merchantPointsToUpdate;
        return updateData;
    };

    const prepareMerchantUpdateData = () => {
        let updateData: MerchantUpdateData = {
            name: null,
            nip: null,
            return_period: null,
            street: null,
            zip_code: null,
            city: null,
        };
        if (merchant && merchantFormData) {
            if (merchant.nip) {
                updateData.return_period = merchant?.return_period !== merchantFormData.return_period ? merchantFormData.return_period : null
            } else {
                forEach(omit(merchantFormData, 'id', 'merchant_points'), ((value: string | number | undefined | MerchantPointSimple[], key: string) => {
                    if (typeof value == "string" || typeof value == "number") {
                        updateData[key] = value
                    }
                }));
            }
        }

        return some(updateData, field => !!field) ? updateData : undefined;

    };

    const saveSuccessCallback = () => {
        setIsSuccessModalOpen(true);
        dispatch(setShouldBlockNavigation(false));
    };

    const saveErrorCallback = () => {
        setIsErrorModalOpen(true);
        dispatch(setShouldBlockNavigation(false));
    };

    const onSaveClick = () => {
        if (merchant && merchant.id) {
            const merchantHasMultiplePoints = merchant.merchant_points.length > 1;
            const merchantUpdateData = prepareMerchantUpdateData();
            if (isMerchantPointAdded)
                createMerchantPointAndUpdateMerchantHandler(merchantPointFormData, setIsSaveButtonLoading, saveSuccessCallback, saveErrorCallback, merchantUpdateData);
            else if (merchantHasMultiplePoints)
                setIsApplyChangesModalOpen(true);
            else if (merchantPoint && merchantPoint.id) {
                const merchantPointUpdateData = prepareMerchantPointUpdateData();
                updateMerchantPointHandler(merchantPoint.id, merchantPointUpdateData, setIsSaveButtonLoading, saveSuccessCallback, saveErrorCallback, merchantUpdateData)
            }
        }
    };

    const handleApplyChanges = (merchantPointsToUpdate: number[]) => {
        if (merchantPoint.id) {
            const merchantUpdateData = prepareMerchantUpdateData();
            const merchantPointUpdateData = prepareMerchantPointUpdateData(merchantPointsToUpdate);
            updateMerchantPointHandler(merchantPoint.id, merchantPointUpdateData, setIsSaveButtonLoading, saveSuccessCallback, saveErrorCallback, merchantUpdateData)
        }
    };

    const getUnfilledFields = () => {
        const pointRequiredFields = [
            'name',
            'address',
            'service_types',
            'opening_hours',
            'phone'
        ];

        const unfilledFields = pointRequiredFields.filter(field => {
            if (field === 'service_types') {
                return isEmpty(merchantPointFormData.service_types) ||
                    !merchantPointFormData.service_types.some(s => s.is_main && s.name);
            }
            if (field === 'opening_hours') {
                return !isOpeningHoursFilled(merchantPointFormData.opening_hours);
            }
            if (field === 'address') {
                return !merchantPointFormData.address && !merchantPointFormData.longitude && !merchantPointFormData.latitude
            }
            if (field === 'phone') {
                return !phoneData.number || !phoneData.countryCode
            }
            return !merchantPointFormData[field];
        });

        const unRequiredFields = ['deposit', 'blocked_funds', 'deposit_requirement'];
        Object.keys(merchantFormData).forEach(field => {
            if (!merchantFormData[field] && !unRequiredFields.includes(field)) {
                unfilledFields.push('merchant_' + field);
            }
        });

        return unfilledFields;
    };

    const validPhoneNumber: boolean = !!merchantPointFormData.phone && isValidPhoneNumber(phoneData.number, phoneData.countryCode as CountryCode || 'PL');

    interface TelephoneErrorKey {
        telephoneError: string
    }

    const errorMessage: Record<keyof TelephoneErrorKey, string> = {
        telephoneError: "Niepoprawny numer telefonu",
    };

    const isFormFilled = isEmpty(getUnfilledFields());

    const isSaveButtonDisabled = () => {
        const isFormEdited = isEdited();
        return !isFormFilled || !isFormEdited || !validPhoneNumber
    };


    return (
        <>
            <NotificationPopup/>
            <div className="merchant-point-form">
                <h2>Wypełnij dane sklepu</h2>
                <div className="form">
                    <div className="form-section-wrapper">
                        {!isMerchantPointAdded &&
                            <div className="input-wrapper" onClick={() => setIsTerminalModalOpen(true)}>
                            <span className="link link-button">
                                <img src={icons.cogIcon} alt="cog"/>
                                Zarządzaj terminalami
                            </span>
                            </div>}
                        <NIPSearch merchant={merchantFormData} setMerchant={setMerchantFormData}
                                   isMerchantConfigured={isMerchantConfigured}/>
                        <Input<Merchant, 'return_period'>
                            value={merchantFormData}
                            onChange={setMerchantFormData}
                            name="return_period"
                            type="number"
                            max={999}
                            label="Okres zwrotu (dni)"
                            tooltipTitle="Okres zwrotu"
                            tooltipBody="Jeżeli w Twoim sklepie możliwe są zwroty nienaruszonych towarów, wpisz w tym polu maksymalną liczbę dni, w których tego zwrotu można dokonać."
                            isRequired
                        />
                        <Input<MerchantPoint, 'name'>
                            value={merchantPointFormData}
                            onChange={setMerchantPointFormData}
                            name="name"
                            label={isMerchantConfigured ? 'Nazwa punktu' : 'Nazwa pierwszego punktu'}
                            isRequired
                        />
                        <AddressGoogleSearch
                            merchantPoint={merchantPointFormData}
                            setMerchantPoint={setMerchantPointFormData}
                            isMerchantConfigured={isMerchantConfigured}
                        />
                        <OpeningHoursSection
                            openingHours={merchantPointFormData.opening_hours}
                            setOpeningHours={(openingHours) => changeMerchantPointFormData('opening_hours', openingHours)}/>
                    </div>
                    <div className="form-section-wrapper">
                        <div className="input-wrapper">
                            <AddImage
                                label="Logo"
                                width={500}
                                height={500}
                                type="logo-image"
                                image={merchantPointFormData.logo_image}
                                setImage={(image) => changeMerchantPointFormData('logo_image', image)}
                            />
                        </div>
                        <div className="input-wrapper">
                            <AddImage width={1200}
                                      height={500}
                                      label="Tło karty"
                                      type="background-image"
                                      image={merchantPointFormData.background_image}
                                      setImage={(image) => changeMerchantPointFormData('background_image', image)}
                                      setBackgroundColor={(color: number | null) => changeMerchantPointFormData('background_color', color)}
                                      selectedColor={merchantPointFormData.background_color}
                            />
                            <ColorPicker
                                colorRange={10}
                                color={merchantPointFormData.background_color}
                                setColor={(color) => changeMerchantPointFormData('background_color', color)}
                            />
                        </div>
                    </div>
                    <div className="form-section-wrapper address-color-picker">
                        <div className="input-wrapper">
                            <div className="label-wrapper">
                                <label>Kolor tekstu z adresem</label>
                                <Tooltip id="AddressColorTooltip" className="tooltip" noArrow={true}>
                                    <p className="tooltip-title">Adres punktu wyświetlany na twojej karcie</p>
                                    <p className="tooltip-body">Gdy posiadasz więcej niż 1 punkt, na twojej karcie
                                        zaczną wyświetlać się adresy poszczególnych punktów. Służy to temu, aby
                                        rozróżnić różne punkty utworzone w obrębie twojej sieci. Dobierz kolor tekstu
                                        tak, aby był dobrze widoczny na tle twojej karty.</p>
                                </Tooltip>
                                <img src={icons.infoIcon} alt="info" data-tooltip-id="AddressColorTooltip"
                                     data-tooltip-place="top-end"/>
                            </div>
                            <ColorPicker
                                colorRange={2}
                                color={merchantPointFormData.address_color}
                                setColor={(color) => changeMerchantPointFormData('address_color', color)}
                            />
                        </div>
                    </div>
                    <ServiceTypesSelect
                        serviceTypes={merchantPointFormData.service_types}
                        setServiceTypes={(serviceTypes) => changeMerchantPointFormData('service_types', serviceTypes)}/>

                    <CashbackLevelSelect
                        merchantPointFormData={merchantPointFormData}
                        setMerchantPointFormData={setMerchantPointFormData}
                    />
                    <div className="form-section-wrapper">
                        <div className="input-wrapper">
                            <div className="label-wrapper">
                                <label>Numer Kontaktowy <span className="required-mark"> *</span></label>
                                <Tooltip id="PhoneNumberTooltip" className="tooltip" noArrow={true}>
                                    <p className="tooltip-title">Numer Kontaktowy wyświetlany w twoim sklepie</p>
                                    <p className="tooltip-body">Na ten numer, będziesz otrzymywał powiadomienia SMS
                                        odnośnie Rezerwacji i Zamówień. Będzie on wyświetlany jako numer kontaktowy w
                                        twoim sklepie</p>
                                </Tooltip>
                                <img src={icons.infoIcon} alt="info" data-tooltip-id="PhoneNumberTooltip"
                                     data-tooltip-place="top-end"/>
                            </div>
                            <div className="phone-wrapper">
                                <CountrySelect setCountry={(countryCode) => setPhoneData((prevState) => ({
                                    ...prevState,
                                    countryCode
                                }))}/>
                                <Input<PhoneDataInterface, 'number'>
                                    value={phoneData}
                                    displayedValue={new AsYouType(phoneData.countryCode as CountryCode).input(phoneData.number)}
                                    onChange={setPhoneData}
                                    name="number"
                                    type="tel"
                                    showError
                                    placeholder="Numer telefonu"
                                    validate={() => validPhoneNumber ? null : 'telephoneError'}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-section-wrapper">
                        <div className="input-wrapper single-line-input">
                            <label>Punkt Waycup</label>
                            <Switch checked={merchantPointFormData.waycup_point}
                                    setChecked={(value) => {
                                        changeMerchantPointFormData('waycup_point', value);
                                        value && changeMerchantPointFormData('waycup_partner', false);
                                    }}/>
                        </div>
                    </div>
                    <div className="form-section-wrapper">
                        <div className="input-wrapper single-line-input">
                            <label>Sklep Partnerski Waycup</label>
                            <Switch checked={merchantPointFormData.waycup_partner}
                                    setChecked={(value) => {
                                        changeMerchantPointFormData('waycup_partner', value);
                                        value && changeMerchantPointFormData('waycup_point', false);
                                    }}/>
                        </div>
                    </div>
                    <div className="form-section-wrapper">
                        <div className="input-wrapper single-line-input">
                            <label htmlFor="NumberOfCups">Podaj liczbę kubków na stanie</label>
                            <Input<MerchantPoint, 'cup_count'>
                                value={merchantPointFormData}
                                onChange={setMerchantPointFormData}
                                name="cup_count"
                                type="number"
                                isDisabled={
                                    (!merchantPointFormData.waycup_point && !merchantPointFormData.waycup_partner) ||
                                    (!!merchantPoint.id && (merchantPoint.waycup_point || merchantPoint.waycup_partner))
                                }
                            />
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button label="Resetuj zmiany"
                                onClick={onResetChangesClick}
                                disabled={isSaveButtonLoading}
                                type="Default"/>
                        <Button label="Zapisz"
                                onClick={isCashbackChanged && !isMerchantPointAdded && merchant?.nip ?
                                    () => setIsConfirmModalOpen(true) :
                                    onSaveClick
                                }
                                loading={isSaveButtonLoading}
                                disabled={isSaveButtonDisabled() || isSaveButtonLoading}
                                dataTooltipId="UnFilledDataTooltip"
                        />
                        {!isFormFilled &&
                            <Tooltip id="UnFilledDataTooltip" className="tooltip" noArrow={true}>
                                <p className="tooltip-title">Aby zapisać wypełnij poniższe pola</p>
                                {getUnfilledFields().map((field, index) =>
                                    <p className="tooltip-body"
                                       key={`UnfilledField${index}`}>{formFieldsDict[field]}</p>
                                )}
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
            <CardPreview backgroundColor={merchantPointFormData.background_color}
                         backgroundImage={merchantPointFormData.background_image}
                         logo={merchantPointFormData.logo_image}
                         address={isMerchantPointAdded || (merchant && merchant.merchant_points.length > 1) ?
                             merchantPointFormData.address : undefined}
                         addressColor={merchantPointFormData.address_color}
                         serviceTypes={merchantPointFormData.service_types}
            />
            <TerminalManagementModal isOpen={isTerminalModalOpen} setIsOpen={setIsTerminalModalOpen}/>
            {merchant && merchantPoint && merchantPoint.id &&
                <ApplyChangesModal isOpen={isApplyChangesModalOpen}
                                   setIsOpen={setIsApplyChangesModalOpen}
                                   merchantPoints={merchant.merchant_points}
                                   currentMerchantPointId={merchantPoint.id}
                                   onApplyChanges={handleApplyChanges}
                                   onCancel={() => dispatch(setShouldBlockNavigation(true))}
                />
            }
            <ConfirmChangeModal
                isOpen={isConfirmModalOpen}
                setIsOpen={setIsConfirmModalOpen}
                title={"Czy na pewno chcesz zmienić wysokość cashbacku?"}
                onConfirm={onSaveClick}
            />
            <SaveResponseModal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)}/>
            <SaveResponseModal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} error={true}/>
        </>
    )
};

export default MerchantPointForm;