import request, {GET} from "../../http/request";
import {handleError} from "../../http/handleError";


export const getAdminMerchants = async () => {
    try {
        const {data} = await request(GET, `filter_options/merchant_list`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getAdminFirstTransaction = async () => {
    try {
        const {data} = await request(GET, `filter_options/first_transaction`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getAdminDashboardMapFilterOptions = async () => {
    try {
        const {data} = await request(GET, `filter_options/dashboard_map`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getAdminMerchantTransactionsFilterOptions = async () => {
    try {
        const {data} = await request(GET, `filter_options/merchant_transactions`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getBanksFilterOptions = async () => {
    try {
        const {data} = await request(GET, 'filter_options/banks', {}, {}, true);
        return data;
    } catch (error) {
        throw error;
    }
};



export const getAdminCupsTableFilterOptions = async () => {
    try {
        const {data} = await request(GET, `filter_options/cups_table`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};