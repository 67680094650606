import React, {useCallback} from 'react';
import {AdvancedMarker, Pin} from '@vis.gl/react-google-maps';
import {toNumber} from "lodash";
import {getServiceTypeIcon} from "../../../../services/images";
import {getMainServiceType} from "../../../services/utils";
import {getServiceTypeTile} from "../../../../services/dictionaries/serviceTypesDict";
import {MarkerProps} from "../../common/map/types";
import {AdminCupsMapPoint} from "../../../services/interfaces";


const AdminCupsMapMarker: React.FC<MarkerProps<AdminCupsMapPoint>> = ({
                                                                               item: merchantPoint,
                                                                               onClick,
                                                                               setMarkerRef
                                                                           }) => {
    const handleClick = useCallback(() => onClick(merchantPoint), [onClick, merchantPoint]);
    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement) => merchantPoint.id &&
            setMarkerRef(marker, merchantPoint.id),
        [setMarkerRef, merchantPoint.id]
    );

    const mainServiceType = getMainServiceType(merchantPoint);
    let serviceTypeDetails = undefined;
    if (mainServiceType) {
        serviceTypeDetails = getServiceTypeTile(mainServiceType.name);
        if (!serviceTypeDetails)
            return <></>;
    } else
        return <></>;

    return (
        <AdvancedMarker position={{lat: toNumber(merchantPoint.latitude), lng: toNumber(merchantPoint.longitude)}}
                        ref={ref} onClick={handleClick}>
            <Pin background="#ffffff" borderColor="#ccc" scale={1}>
                <img src={getServiceTypeIcon(mainServiceType.name, true)} alt=""
                     width={serviceTypeDetails.iconWidth / 2}
                     height={serviceTypeDetails.iconHeight / 2}/>

            </Pin>

        </AdvancedMarker>
    );
};

export default AdminCupsMapMarker;