import {Reservation} from "../../../services/interfaces";
import {ReservationStatusEnum} from "../../../services/dictionaries/enums";
import {parseQueryString} from "../../../services/utils";
import {icons} from "../../../services/images";
import ActionModal from "../../modals/ActionModal";
import React, {useRef, useState} from "react";
import {getReservationDateTime, isPastReservation} from "../../../services/dates";
import {cancelReservationHandler} from "../../../handlers/bookingHandlers";
import ConfirmChangeModal from "../../modals/ConfirmChangeModal";
import getNotificationMessage from "../../../services/dictionaries/notification";

interface Props {
    reservation: Reservation,
    filters: string,
    onClick: () => void
    updateTableCallback: () => void
}


const ReservationTableRow = ({reservation, filters, onClick, updateTableCallback}: Props) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
    const actionRef = useRef<(HTMLTableCellElement | null)>(null);
    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];

    let statusColor = '';
    let hasWarning = false;

    let actions = [
        {
            title: 'Podgląd',
            onClick: () => {
                onClick();
                toggleActionModal();
            },
            className: ''
        }
    ];


    const setAsCanceled = () => {
        setShowCancelConfirmation(true);
        toggleActionModal();
    };

    switch (reservation.status) {
        case "canceled":
            statusColor = 'red';
            break;
        case "placed":
        case "paid":
            statusColor = 'blue';
            hasWarning = true;
            if (!isPastReservation(reservation)) {
                actions.push({
                    title: 'Anuluj zamówienie',
                    onClick: setAsCanceled,
                    className: 'delete'
                });
            }
            break;
        case "completed":
            statusColor = 'grey';
    }
    let reservationStatus = 'Inna';
    if (reservation.status in ReservationStatusEnum) {
        reservationStatus = ReservationStatusEnum[reservation.status];
    }
    const appointmentDate = getReservationDateTime(reservation.appointment_date, reservation.duration)

    const toggleActionModal = () => {
        const item = actionRef.current;
        if (item) {
            const {right, bottom} = item.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };
    return (
        <tr>
            <td className="clickable-cell">
                <span onClick={onClick}>#{reservation.id}</span>
            </td>
            {!excludedColumns.includes('client_id') &&
                <td className="client-id-cell">{reservation.user_id}</td>}
            {!excludedColumns.includes('employee') && <td>{reservation.employee.name}</td>}
            {!excludedColumns.includes('appointment_date') && <td>{appointmentDate}</td>}
            {!excludedColumns.includes('status') &&
                <td className={`cashback-status-cell ${statusColor}`}>
                    {hasWarning && <span className="">!</span>}
                    {reservationStatus}
                </td>
            }
            {!excludedColumns.includes('service_name') && <td>{reservation.service_name}</td>}
            {!excludedColumns.includes('amount') && <td>{reservation.price_at_time}</td>}
            <td className="actions-button-cell" ref={actionRef}>
                <div className="actions-button" onClick={toggleActionModal}>
                    <img src={icons.actionsButtonIcon}
                         alt="actionsButtonIcon"
                    />
                </div>
                <ActionModal isOpen={isActionModalOpen}
                             onClose={() => setIsActionModalOpen(false)}
                             actions={actions}
                             x={actionModalPosition.x} y={actionModalPosition.y}/>
                <ConfirmChangeModal isOpen={showCancelConfirmation}
                                    setIsOpen={setShowCancelConfirmation}
                                    title="Jesteś pewien?"
                                    onConfirm={() => cancelReservationHandler({
                                        reservationId: reservation.id,
                                        callback: updateTableCallback,
                                    })}
                                    body={getNotificationMessage("cancel_reservation_info")}
                                    confirmLabel="Tak, Anuluj"
                />
            </td>
        </tr>
    )
};

export default ReservationTableRow;