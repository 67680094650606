import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {DatePreset, DateRange} from "../../../services/interfaces";
import {getAdminDatePreset, getAdminDateRange,} from "../../redux/selectors/adminViewSettingsSelectors";
import {setAdminDatePresetAction, setAdminDateRangeAction} from "../../redux/adminViewSettings";
import DateRangePicker from "../../../components/common/DateRangePicker";
import AdminCupsMap from "./map/AdminCupsMap";
import AdminCupsTable from "./table/AdminCupsTable";
import AdminMerchantPointServiceButton from "../common/AdminMerchantPointServiceButton";

const AdminCups = () => {
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const datePreset: DatePreset | null = useSelector(getAdminDatePreset);
    const dispatch = useDispatch();


    return (
        <div className="dashboard-content with-sticky-bar admin">
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Ekokubki - Payprofit</h2>
                    <AdminMerchantPointServiceButton/>
                </div>
                <div className="header-section">
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setAdminDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setAdminDatePresetAction(datePreset))}
                                     admin={true}
                    />
                </div>
            </div>
            <AdminCupsMap/>
            <div className="block-wrapper">
                <AdminCupsTable/>
            </div>
        </div>
    )
};

export default AdminCups;