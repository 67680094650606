import {DateRange, OrderType, TableHeader} from "../../../../services/interfaces";
import React, {useEffect, useState} from "react";
import {CupsMerchantSummary, CupsTableSort,} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getAdminDateRange, getAdminGlobalFilters} from "../../../redux/selectors/adminViewSettingsSelectors";
import AdminCupsMerchantRow from "./AdminCupsMerchantRow";
import Table from "../../../../components/common/Table";
import {
    getAdminCupsTableCount,
    getAdminCupsTableList,
    getAdminCupsTableMaxPage
} from "../../../redux/selectors/adminCupsSelectors";
import {getAdminCupsTableHandler} from "../../../handlers/cupsHandlers";
import AdminCupsTableFiltersModal from "./AdminCupsTableFiltersModal";


const AdminCupsTable = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const data: CupsMerchantSummary[] = useSelector(getAdminCupsTableList);
    const maxPage: number = useSelector(getAdminCupsTableMaxPage);
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const count: number = useSelector(getAdminCupsTableCount);
    const globalFilters = useSelector(getAdminGlobalFilters);

    const headers: TableHeader<CupsTableSort>[] = [
        {name: 'Nazwa', sort: 'name', digit: false},
        {name: 'Branża', sort: '', digit: false},
        {name: 'Oddziały', sort: 'points_count', digit: true},
        {name: 'Liczba akcji', sort: 'cup_actions_count', digit: true},
        {name: 'Stan magazynowy', sort: 'cup_count', digit: true},
    ];

    useEffect(() => {
        getAdminCupsTableHandler({page, query, sort, order, filters: filterString, pageSize, dateRange, globalFilters})
    }, [page, query, sort, order, filterString, pageSize, dateRange, globalFilters]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);

    return (
        <Table page={page}
               setPage={setPage}
               pageSize={pageSize}
               setPageSize={setPageSize}
               query={query}
               setQuery={setQuery}
               sort={sort}
               setSort={setSort}
               order={order}
               setOrder={setOrder}
               count={count}
               filters={filterString}
               setFilters={setFilterString}
               maxPage={maxPage}
               headers={headers}
               renderRows={() => data.map((merchant, index) =>
                   <AdminCupsMerchantRow merchant={merchant}
                                         filters={filterString}
                                         key={`MerchantRow${index}`}/>
               )}
               Modal={AdminCupsTableFiltersModal}/>
    )
};


export default AdminCupsTable;