import Modal from "react-modal";
import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";
import {FilterModalProps} from "../../../../services/interfaces";
import {AdminDashboardMapFilterOptions} from "../../../services/interfaces";
import {icons} from "../../../../services/images";
import FiltersSlider from "../../../../components/modals/filters/FiltersSlider";
import Button from "../../../../components/common/Button";
import {getAdminDashboardMapFilterOptionsHandler} from "../../../handlers/filterOptionsHandlers";
import {getAdminDashboardMapFilterOptions} from "../../../redux/selectors/adminViewSettingsSelectors";


const AdminCupsMapFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: AdminDashboardMapFilterOptions | null = useSelector(getAdminDashboardMapFilterOptions);
    const [cupActionCountSlider, setCupActionCountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.cup_action.min_count, filterOptions.cup_action.max_count] : null);


    useEffect(() => {
        getAdminDashboardMapFilterOptionsHandler()
    }, []);

    useEffect(() => {
        if (filterOptions && (cupActionCountSlider === null)) {
            setCupActionCountSlider([filterOptions.cup_action.min_count, filterOptions.cup_action.max_count])
        }
    }, [filterOptions]);


    const clearFilters = () => {
        setCupActionCountSlider([filterOptions?.cup_action.min_count || 0, filterOptions?.cup_action.max_count || 100]);
    };

    const applyFilters = () => {
        if (!cupActionCountSlider) {
            return;
        }
        const urlFilters = '' +
            '&cup_action_count=' + cupActionCountSlider.join(',')
        setFilters(urlFilters);
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersSlider
                    label="Liczba akcji kubkowych"
                    min={filterOptions?.cup_action.min_count || 0}
                    max={filterOptions?.cup_action.max_count || 100}
                    value={cupActionCountSlider}
                    onChange={setCupActionCountSlider}
                    wholeNumber
                />
            </div>
            <div className="modal-footer">
                {filterOptions &&
                    <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default AdminCupsMapFiltersModal;