import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import {RangeBase, TargetGroupRangeKey} from "../../../services/interfaces";
import RangeInput from "./RangeInput";
import {forEach, isEqual, some} from "lodash";


interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    savedRanges: RangeBase[]
    onUpdateRanges: (ranges: RangeBase[]) => void;
    rangeKey: TargetGroupRangeKey
}

const TargetGroupRangesModal = ({isOpen, setIsOpen, title, savedRanges, onUpdateRanges, rangeKey}: Props) => {

    const [ranges, setRanges] = useState<RangeBase[]>([]);
    const wholeNumber = rangeKey === "transactions_ranges" || rangeKey === "age_ranges";
    const displayedRanges = rangeKey === "age_ranges" ?  ranges.slice(0, 4) : ranges;

    useEffect(() => {
        setRanges(savedRanges)
    }, [savedRanges]);


    const toggleModal = () => {
        setIsOpen(!isOpen);
        setRanges(savedRanges)
    };

    const changeRange = (range: RangeBase, field: "min" | "max", value: number | null) => {
        let newRanges = ranges.map(r =>
            r.id === range.id ? {...r, [field]: value} : r
        );
        setRanges(newRanges);
    };

    const checkRangeMinError = (range: RangeBase, index: number): boolean => {
        if (range.min === null) return true;
        if (index === 0) {
            return wholeNumber ? range.min > 1 : range.min > 0;
        }
        const prevRange = ranges[index - 1];
        return prevRange.max !== null && range.min > prevRange.max + 1;
    };

    const checkRangeMaxError = (range: RangeBase, index: number): boolean => {
        const maxIndex = rangeKey === "age_ranges" ? 3 : 4;
        // Last range should only have min field, so no max error
        if (index === maxIndex) return false;
        
        if (range.max === null) return true;

        const nextRange = ranges[index + 1];
        if (nextRange && nextRange.min) {
            const minMaxCheck = rangeKey === "transactions_ranges" 
                ? range.min !== null && range.max < range.min
                : range.min !== null && range.max <= range.min;
            return minMaxCheck || (nextRange.min > range.max + 1)
        } else {
            return range.min !== null && (
                rangeKey === "transactions_ranges" 
                    ? range.max < range.min
                    : range.max <= range.min
            );
        }
    };

    const onResetChanges = () => {
        setRanges(savedRanges)
    };

    const onSave = () => {
        onUpdateRanges(ranges);
        setIsOpen(!isOpen);
    };

    const hasErrors = some(displayedRanges, (range, index) => checkRangeMinError(range, index) || checkRangeMaxError(range, index));
    const isDisabled = isEqual(savedRanges, ranges) || hasErrors;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content statistics-ranges-modal"
        >
            <div className="modal-header">
                <h5>{title}</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <p className="subtitle">Ustal zakresy</p>
                <div className="range-container">
                    {displayedRanges.map((range, index) =>
                        <div className="range-section" key={`StatisticsRangeRow${index}`}>
                            <p className="label">Zakres {index + 1}</p>
                            <div className="input-section">
                                <RangeInput
                                    range={range}
                                    index={index}
                                    ranges={ranges}
                                    changeRange={(field, value) => changeRange(range, field, value)}
                                    hasMinError={checkRangeMinError(range, index)}
                                    hasMaxError={checkRangeMaxError(range, index)}
                                    maxIndex={rangeKey === 'age_ranges' ? 3 : 4}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="modal-footer with-error">
                {hasErrors &&
                    <p className="error-message">
                        Występują luki między zakresami. Dostosuj wartości, aby utworzyć zamknięty zbiór
                    </p>
                }
                <div className="buttons">
                    <Button label="Resetuj zmiany"
                            onClick={onResetChanges}
                            type="Default"/>
                    <Button label="Zapisz"
                            onClick={onSave}
                            disabled={isDisabled}
                    />
                </div>
            </div>
        </Modal>
    )
};

export default TargetGroupRangesModal;