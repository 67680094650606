import React, {useEffect, useMemo, useState} from "react";
import Modal from "react-modal";
import {isEmpty, map, maxBy, minBy} from "lodash";
import {
    AppUserCashbackStatus,
    AppUserCupAction,
    AppUserDetails,
    AppUserTransaction,
    TransactionStatus
} from "../../services/interfaces";
import {cloudFrontUrl, icons} from "../../services/images";
import {DOUBLE_DECIMAL_NUMBER_FORMAT, formatNumber, WHOLE_NUMBER_FORMAT} from "../../services/numbers";
import {categorizeByDate, displayConvertedDate, getDateGroupTitle} from "../../services/dates";
import {CupActionTypeEnum, GenderEnum} from "../../services/dictionaries/enums";
import AppUserFiltersModal, {AppUserFilterOptions, AppUserFilters} from "./AppUserFiltersModal";
import {DEFAULT_TRANSACTION_STATUS_STATE} from "./filters/TransactionHistoryFiltersModal";
import moment from "moment";
import Button from "../common/Button";
import {getAppUserName} from "../../services/helpers";
import {useDispatch, useSelector} from "react-redux";
import {modalOpenAction} from "../../redux/navigation";
import {setContactPageAction} from "../../redux/chat";
import {getChatDetailsHandler} from "../../handlers/chatHandlers";
import {setAdminAppUserDetailsAction} from "../../admin/redux/adminUsers";
import {setStatisticsUserDetailsAction} from "../../redux/statistics";
import {getAppUserDetailsHandler} from "../../admin/handlers/adminUsersHandlers";
import {getStatisticsUserDetailsHandler} from "../../handlers/statisticsHandlers";
import {getStatisticsUserDetails} from "../../redux/selectors/statisticsSelectors";
import {getAdminAppUserDetails} from "../../admin/redux/selectors/adminUsersSelectors";
import LoadingScreen from "../common/LoadingScreen";


interface Props {
    userId: number | null,
    onClose: () => void;
    admin?: boolean
}

const UserTransaction = ({transaction}: { transaction: AppUserTransaction }) => {
    const getCashbackInfo = () => {
        let icon = icons.coinBluePrimaryIcon;
        switch (transaction.status) {
            case "wallet": {
                icon = icons.coinGreenIcon;
                break;
            }
            case "receivables": {
                icon = icons.coinBluePrimaryIcon;
                break;
            }
            case "withdrawn": {
                icon = icons.coinGrayIcon;
                break;
            }
            case "rejected": {
                return (
                    <span className="cashback">
                        <img src={icons.coinRedIcon} alt=""/>
                        <span className="rejected">Odrzucone</span>
                    </span>
                );
            }
        }
        return (
            <span className="cashback">
                <img src={icon} alt=""/>
                +{formatNumber(transaction.cashback_accrued)}
            </span>
        );
    };

    return (
        <div className="transaction-item not-selectable" key={transaction.id}>
            <div className="icon">
                <img
                    src={transaction.merchant_point.logo_image ? `${cloudFrontUrl}${transaction.merchant_point.logo_image}` : ""}
                    alt=""/>
            </div>
            <div className="content">
                <span className="name">{transaction.merchant_point.name}</span>
                <span className="amount">{formatNumber(+transaction.amount)}</span>
                <div className="footer">
                    <span className="date">{displayConvertedDate(transaction.trade_date)}</span>
                    {getCashbackInfo()}
                </div>
            </div>
        </div>
    )
};

const UserCupAction = ({cupAction}: { cupAction: AppUserCupAction }) => {
    let icon = '';
    let cupActionType = 'Nieznany';
    if (cupAction.type in CupActionTypeEnum) {
        cupActionType = CupActionTypeEnum[cupAction.type];
    }
    switch (cupAction.type) {
        case "collected":
            icon = icons.cupCollectedActionIcon;
            break;
        case "returned":
            icon = icons.cupReturnedActionIcon;
            break;
        case "used":
            icon = icons.cupUsedActionIcon;
            break;
        case "exchanged":
            icon = icons.cupExchangedActionIcon;
            break;
        case "collected_and_used":
            icon = icons.cupCollectedActionIcon;
            break;
        case "exchanged_and_used":
            icon = icons.cupExchangedActionIcon;
            break;
    }

    return (
        <div className="cup-action-item" key={cupAction.id}>
            <div className="icon">
                <img
                    src={icon}
                    alt=""/>
            </div>
            <div className="content">
                        <span
                            className="name">{cupAction.merchant_point.name} | {cupAction.merchant_point.address}</span>
                <span className="date">{displayConvertedDate(cupAction.date)}</span>
                <span className="cup-action-type">
                {cupActionType} - <span className="bold">Kubek</span>
            </span>
            </div>
        </div>
    )
};

const UserDetails = ({user}: { user: AppUserDetails }) => {
    let gender = 'Inna';
    if (user.gender in GenderEnum) {
        gender = GenderEnum[user.gender];
    }
    return (
        <div className="user-details">
            <div>
                <span className="title">{getAppUserName(user)}</span>
            </div>
            <div>
                <span className="title">Płeć: </span>
                <span className="value">{gender}</span>
            </div>
            <div>
                <span className="title">Wiek: </span>
                <span className="value">{user.age || "Brak danych"}</span>
            </div>
            <div>
                <span className="title">Uzyskany Cashback: </span>
                <span className="value">{formatNumber(user.cashback)}</span>
            </div>
            <div>
                <span className="title">Liczba transakcji: </span>
                <span className="value">{formatNumber(user.transactions_count, WHOLE_NUMBER_FORMAT)}</span>
            </div>
            {!!user.bank_accounts_count &&
                <div>
                    <span className="title">Liczba powiązanych kont bankowych: </span>
                    <span className="value">{user.bank_accounts_count}</span>
                </div>
            }
            {user.cup_status &&
                <div>
                    <span className="title">Kubki: </span>
                    <span className="value">{user.cup_status.cup_count}</span>
                </div>
            }
            <div>
                <span className="title">Liczba akcji kubkowych: </span>
                <span className="value">{user.cup_actions.length}</span>
            </div>
            <div>
                <span className="title">Data dołączenia do PayProfit: </span>
                <span className="value">{moment(user.created_at).format("DD.MM.YYYY")}</span>
            </div>
            {user.cup_status &&
                <div>
                    <span className="title">Redukcja śladu CO2: </span>
                    <span
                        className="value">{formatNumber(user.cup_status.co2_emission, DOUBLE_DECIMAL_NUMBER_FORMAT)}</span>
                </div>
            }
            <div>
                <span className="title">Średnia kwota: </span>
                <span className="value">{formatNumber(user.average_amount)}</span>
            </div>
        </div>
    )
};


const UserCashbackStatus = ({cashbackStatus}: { cashbackStatus: AppUserCashbackStatus }) => {
    return (
        <div className="cashback-status">
        <div className="cashback-status-pill">
                <div>
                    <span className="dot green"/>
                    <div>
                        <p className="title">Portfel</p>
                        <p className="value">{formatNumber(cashbackStatus.wallet)}</p>
                    </div>
                </div>
            </div>
            <div className="cashback-status-pill">
                <div>
                    <span className="dot blue"/>
                    <div>
                        <p className="title">Oczekujące</p>
                        <p className="value">{formatNumber(cashbackStatus.receivables)}</p>
                    </div>
                </div>
            </div>
            <div className="cashback-status-pill">
                <div>
                    <span className="dot gray"/>
                    <div>
                        <p className="title">Wypłacone</p>
                        <p className="value">{formatNumber(cashbackStatus.withdrawn)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

const AppUserDetailsModal = ({onClose, userId, admin = false}: Props) => {
    const [filtersModalOpen, setFiltersModalOpen] = useState(false);
    const [filters, setFilters] = useState<AppUserFilters>({
        query: '',
        fromDate: null,
        toDate: null,
        amountSlider: null,
        cashbackSlider: null,
        status: DEFAULT_TRANSACTION_STATUS_STATE
    });
    const [mode, setMode] = useState<'transactions' | 'cups'>('transactions');
    const dispatch = useDispatch();

    const user = useSelector(admin ? getAdminAppUserDetails : getStatisticsUserDetails);

    useEffect(() => {
        if (userId !== null)
            admin ?
                getAppUserDetailsHandler(userId) :
                getStatisticsUserDetailsHandler(userId)
    }, [userId]);

    const filterOptions: AppUserFilterOptions | null = useMemo(() => {
        if (!user)
            return null;
        return {
            min_amount: minBy(user.transactions, 'amount')?.amount || 0,
            max_amount: maxBy(user.transactions, 'amount')?.amount || 100,
            min_cashback: minBy(user.transactions, 'cashback_accrued')?.cashback_accrued || 0,
            max_cashback: maxBy(user.transactions, 'cashback_accrued')?.cashback_accrued || 100
        }
    }, [user]);


    const filteredTransactions = useMemo(() => {
        if (!user) return [];

        return user.transactions.filter((transaction) => {
            // Filter by query (search in merchant name or transaction ID)
            if (filters.query &&
                !transaction.merchant_point.name.toLowerCase().includes(filters.query.toLowerCase()) &&
                !transaction.merchant_point.address.toLowerCase().includes(filters.query.toLowerCase()) &&
                !transaction.id.toString().includes(filters.query)) {
                return false;
            }

            // Filter by date range
            if (filters.fromDate && new Date(transaction.trade_date) < filters.fromDate) {
                return false;
            }
            if (filters.toDate && new Date(transaction.trade_date) > filters.toDate) {
                return false;
            }

            // Filter by amount range
            if (filters.amountSlider && (transaction.amount < filters.amountSlider[0] || transaction.amount > filters.amountSlider[1])) {
                return false;
            }

            // Filter by cashback range
            if (filters.cashbackSlider && (transaction.cashback_accrued < filters.cashbackSlider[0] || transaction.cashback_accrued > filters.cashbackSlider[1])) {
                return false;
            }


            // Filter by status
            const selectedStatuses = Object.entries(filters.status)
                .filter(([_, isSelected]) => isSelected)
                .map(([status, _]) => status as TransactionStatus);

            return !(selectedStatuses.length > 0 && !selectedStatuses.includes(transaction.status));
        });
    }, [user, filters]);

    const categorizedTransactionsByDate = useMemo(() => {
        if (!filteredTransactions)
            return null;
        return categorizeByDate(filteredTransactions, 'trade_date');
    }, [filteredTransactions]);

    const filteredCupActions = useMemo(() => {
        if (!user) return [];
        return user.cup_actions.filter((cup_action) => {
            // Filter by query (search in merchant name or transaction ID)
            if (filters.query &&
                !cup_action.merchant_point.name.toLowerCase().includes(filters.query.toLowerCase()) &&
                !cup_action.merchant_point.address.toLowerCase().includes(filters.query.toLowerCase()) &&
                !CupActionTypeEnum[cup_action.type].toLowerCase().includes(filters.query.toLowerCase())
            ) {
                return false;
            }

            // Filter by date range
            if (filters.fromDate && new Date(cup_action.date) < filters.fromDate) {
                return false;
            }
            return !(filters.toDate && new Date(cup_action.date) > filters.toDate);

        })
    }, [user, filters]);

    const categorizedCupActionsByDate = useMemo(() => {
        if (!filteredCupActions)
            return null;
        return categorizeByDate(filteredCupActions, 'date');
    }, [filteredCupActions]);

    const onSendMessageClick = () => {
        if (user) {
            dispatch(modalOpenAction('contact'));
            if (!admin) {
                dispatch(setContactPageAction('chat'));
                getChatDetailsHandler(user.id);
                setStatisticsUserDetailsAction(null);
            } else {
                dispatch(setContactPageAction('user_message'));
            }
            onClose()
        }
    };

    const closeModal = () => {
        onClose();
        dispatch(admin ?
            setAdminAppUserDetailsAction(null) :
            setStatisticsUserDetailsAction(null)
        )
    };

    return (
        <Modal
            isOpen={!!userId}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content app-user-details-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title">
                    Szczegóły użytkownika <span className="blue-primary">#{user ? user.id : ""}</span>
                </h5>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {user ?
                <div className="modal-body">
                    {user && <UserDetails user={user}/>}
                    <div className="user-history-header">
                        <div className="modes">
                            <p className={`mode-title ${mode === 'transactions' ? 'selected' : 'not-selected'}`}
                               onClick={() => setMode('transactions')}>Historia transakcji</p>
                            <span className="horizontal-divider"/>
                            <p className={`mode-title ${mode === 'cups' ? 'selected' : 'not-selected'}`}
                               onClick={() => setMode('cups')}>Ekokubki</p>
                        </div>
                        <div className="user-history-filters">
                            <div className="searchbar">
                                <img src={icons.search} alt="Search"/>
                                <input type="text" placeholder="Szukaj..." className="searchbar-input"
                                       maxLength={50}
                                       value={filters.query}
                                       onChange={event => setFilters({...filters, query: event.target.value})}/>
                            </div>
                            <div className="filters-button" onClick={() => setFiltersModalOpen(true)}>
                                <img src={icons.filterGrey} alt="Filters"/>
                                <p className="label-small">Filtry</p>
                            </div>
                        </div>
                        {user && mode === 'transactions' && user.cashback_status &&
                            <UserCashbackStatus cashbackStatus={user.cashback_status}/>
                        }
                    </div>
                    <div className="user-history-list">
                        {mode === 'transactions' && categorizedTransactionsByDate ?
                            map(categorizedTransactionsByDate, (transactions, group) =>
                                !isEmpty(transactions) &&
                                <div className="transaction-group" key={`TransactionDateGroup${group}`}>
                                    <span className="title">{getDateGroupTitle(group)}</span>
                                    {transactions.map((transaction, index) =>
                                        <UserTransaction
                                            transaction={transaction}
                                            key={`User${user?.id}Transaction${index}`}
                                        />)
                                    }
                                </div>
                            ) :
                            mode === 'cups' && categorizedCupActionsByDate ?
                                map(categorizedCupActionsByDate, (cupActions, group) =>
                                    !isEmpty(cupActions) &&
                                    <div className="cup-action-group" key={`CupActionDateGroup${group}`}>
                                        <span className="title">{getDateGroupTitle(group)}</span>
                                        {cupActions.map((cupAction, index) =>
                                            <UserCupAction
                                                cupAction={cupAction}
                                                key={`User${user?.id}CupAction${index}`}
                                            />)
                                        }
                                    </div>
                                ) :
                                <div className="loader returns"/>
                        }
                        <Button label="Wyślij wiadomość do użytkownika"
                                fullWidth
                                onClick={onSendMessageClick}/>
                    </div>
                </div> :
                <LoadingScreen/>
            }
            <AppUserFiltersModal open={filtersModalOpen}
                                 setOpen={setFiltersModalOpen}
                                 filterOptions={filterOptions}
                                 mode={mode}
                                 setFilters={setFilters}
                                 parentQuery={filters.query}/>
        </Modal>
    )
};

export default AppUserDetailsModal;