import Modal from "react-modal";
import {icons} from "../../services/images";
import Button from "../common/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {HOME_PAGE} from "../../services/dictionaries/paths";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    error?: boolean
}

const SaveResponseModal = ({isOpen, onClose, error=false}: Props) => {
    const navigate = useNavigate();
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            overlayClassName="modal-wrapper"
            className="modal-content popup"
        >
            <div className="modal-body">
                {error ?
                    <div className="success-screen">
                        <img src={icons.errorIcon} alt=""/>
                        <span className="title">Ups coś poszło nie tak...</span>
                    </div> :
                    <div className="success-screen">
                        <img src={icons.successIcon} alt=""/>
                        <span className="title">Pomyślnie zapisano zmiany</span>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <Button label="Powrót do edycji" onClick={onClose} type="Default"/>
                <Button label="Kontynuuj" onClick={() => navigate(HOME_PAGE)}/>
            </div>
        </Modal>

    )
};

export default SaveResponseModal;