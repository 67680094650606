import {icons, images} from "../../../../services/images";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import React from "react";
import {InfoWindowProps} from "../../common/map/types";
import {AdminCupsMapPoint} from "../../../services/interfaces";
import AdminCupsMerchantPointOverview from "./AdminCupsMerchantPointOverview";


const AdminCupsMapInfoWindow: React.FC<InfoWindowProps<AdminCupsMapPoint>> = (merchantPoint) => {

    return (
        <div className="map-info-window">
            <AdminCupsMerchantPointOverview {...merchantPoint}/>
            <div className="map-info-window-details">
                <div>
                    <img src={icons.cupUsedIcon} alt=""/>
                    <span>{formatNumber(merchantPoint.cup_used_count, WHOLE_NUMBER_FORMAT)} użyć</span>
                </div>
                <div>
                    <img src={icons.cupExchangedIcon} alt=""/>
                    <span>{formatNumber(merchantPoint.cup_exchanged_count, WHOLE_NUMBER_FORMAT)} wymian</span>
                </div>
                <div>

                    <img src={icons.cupCollectedIcon} alt=""/>
                    <span>{formatNumber(merchantPoint.cup_collected_count, WHOLE_NUMBER_FORMAT)} odbiorów</span>
                </div>
                <div>
                    <img src={icons.cupReturnedIcon} alt=""/>
                    <span>{formatNumber(merchantPoint.cup_returned_count, WHOLE_NUMBER_FORMAT)} zwrotów</span>
                </div>
            </div>
        </div>
    )

};

export default AdminCupsMapInfoWindow;