import {parseQueryString} from "../../../../services/utils";
import {CupsMerchantSummary} from "../../../services/interfaces";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import {translateServiceType} from "../../../../services/dictionaries/serviceTypesDict";
import {useState} from "react";
import AdminCupsMerchantPointRow from "./AdminCupsMerchantPointRow";


const AdminCupsMerchantRow = ({merchant, filters}: { merchant: CupsMerchantSummary, filters: string }) => {
    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <tr>
                {!excludedColumns.includes('name') &&
                    <td className="merchant-name">
                        {merchant.merchant_points.length > 1 &&
                            <span className={`chevron ${isOpen ? 'up' : 'down'}`}
                                  onClick={() => setIsOpen(!isOpen)}></span>}
                        {merchant.name}
                    </td>}
                {!excludedColumns.includes('service_type') &&
                    <td>{merchant.service_type.map(s => translateServiceType(s)).join(', ')}</td>}
                {!excludedColumns.includes('points_count') &&
                    <td>{merchant.merchant_points.length}</td>}
                {!excludedColumns.includes('cup_actions_count') &&
                    <td className="cell-digit">{formatNumber(merchant.cup_actions_count, WHOLE_NUMBER_FORMAT)}</td>}
                {!excludedColumns.includes('cup_count') &&
                    <td className="cell-digit">{formatNumber(merchant.cup_count, WHOLE_NUMBER_FORMAT)}</td>}
            </tr>
            {isOpen && merchant.merchant_points.map(point =>
                <AdminCupsMerchantPointRow merchantPoint={point} excludedColumns={excludedColumns}/>)}
        </>

    )
};

export default AdminCupsMerchantRow;