import {
    CupAction,
    CupDashboardResponse,
    CupTableResponse,
    DownloadTableProps,
    GetDashboardDataProps,
    GetTableProps
} from "../services/interfaces";
import {getCurrentMerchantPoint, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {store} from "../redux/store";
import {saveAs} from "file-saver";
import {
    downloadMerchantCupActions,
    downloadMerchantPointCupActions,
    getMerchantCupActionsFilterOptions,
    getMerchantCupDashboard,
    getMerchantCupTable,
    getMerchantPointCupActionsFilterOptions,
    getMerchantPointCupDashboard,
    getMerchantPointCupTable,
    manageMerchantPointCupCount,
    setCupActionError
} from "../http/cup";
import {setCupDashboardDataAction, setCupFilterOptionsAction, setCupTableDataAction} from "../redux/cup";
import {
    prepareB64FileToXLSX,
    prepareURLParamForDashboardData,
    prepareURLParamForDownloadTable,
    prepareURLParamForTable
} from "../services/utils";
import {
    setResponseNotificationErrorAction,
    setResponseNotificationSavedAction,
    setScreenLoadingAction
} from "../redux/navigation";
import {getMerchantHandler, getMerchantPointHandler} from "./merchantHandler";

export const getMerchantCupDashboardHandler = (props: GetDashboardDataProps) => {
    store.dispatch(setScreenLoadingAction(true));
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    const urlParam = prepareURLParamForDashboardData(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointCupDashboard(merchantPoint.id, urlParam).then((data: CupDashboardResponse) => {
            store.dispatch(setCupDashboardDataAction(data));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        })
    } else {
        getMerchantCupDashboard(urlParam).then((data: CupDashboardResponse) => {
            store.dispatch(setCupDashboardDataAction(data));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        });
    }
};

export const getMerchantCupTableHandler = (props: GetTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    const urlParam = prepareURLParamForTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointCupTable(merchantPoint.id, urlParam.toString()).then((data: CupTableResponse) => {
            store.dispatch(setCupTableDataAction(data))
        }).catch(error => {
            console.error(error);
        })
    } else {
        getMerchantCupTable(urlParam.toString()).then((data: CupTableResponse) => {
            store.dispatch(setCupTableDataAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const downloadCupActionsTableHandler = (props: DownloadTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const urlParam = prepareURLParamForDownloadTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        downloadMerchantPointCupActions(merchantPoint.id, urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, 'cup_actions.xlsx')
        }).catch(error => {
            console.error(error);
        })
    } else {
        downloadMerchantCupActions(urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, 'cup_actions.xlsx')
        }).catch(error => {
            console.error(error);
        })
    }
};

export const getCupActionsFilterOptionsHandler = () => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointCupActionsFilterOptions(merchantPoint.id).then(data => {
            store.dispatch(setCupFilterOptionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    } else {
        getMerchantCupActionsFilterOptions().then(data => {
            store.dispatch(setCupFilterOptionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const manageMerchantPointCupCountHandler = (merchantPointId: number,
                                                   numberOfCups: number,
                                                   callback: () => void,
                                                   errorCallback: () => void,
                                                   add: boolean = true) => {
    manageMerchantPointCupCount(merchantPointId, numberOfCups, add).then(() => {
        callback();
        getMerchantHandler();
        getMerchantPointHandler(merchantPointId);
    }).catch(error => {
        errorCallback();
        console.error(error);
    })
};


export const setCupActionErrorHandler = (cupAction: CupAction, callback: () => void, cupCountConflictCallback: (withAppUser: boolean) => void) => {
    setCupActionError(cupAction).then(() => {
        callback();
        store.dispatch(setResponseNotificationSavedAction())
    }).catch(error => {
        if (error.status === 409)
            cupCountConflictCallback(error.detail === 'User cup count negative');
        store.dispatch(setResponseNotificationErrorAction());
        console.error(error);
    })
};