import {ChartData, ChartInterval, PieChartData, PreparedPieChartData, PreviousPeriod} from "./interfaces";
import moment from "moment";
import {calculateChange, formatNumber, WHOLE_NUMBER_FORMAT} from "./numbers";
import {isArray, last} from "lodash";

interface prepareChartDataProps {
    chartData: ChartData[],
    prevChartData?: ChartData[],
    interval: ChartInterval
    previousPeriod?: PreviousPeriod
}

export const prepareChartData = ({chartData, prevChartData, interval, previousPeriod}: prepareChartDataProps) => {
    let groupedData = groupByIntervalWithLabel(chartData, interval);
    if (prevChartData && previousPeriod !== 'hide') {
        let prevGroupedData: GroupedData[] = groupByIntervalWithLabel(prevChartData, interval);

        return groupedData.map(({data, label}, index) => {
            const prevGroup = prevGroupedData[index] || {data: [], label: ''};
            const value = getValueFromGroupedData(data);
            const prevValue = getValueFromGroupedData(prevGroup.data);
            const average = getAverageValue(value, data.length);
            const prevAverage = getAverageValue(prevValue, data.length);
            const change = calculateChange(value, prevValue) * 100;
            return {
                name: label,
                prevName: prevGroup.label,
                value,
                prevValue,
                average,
                prevAverage,
                change
            };
        });
    } else {
        return groupedData.map(({data, label}, index) => {
            const value = getValueFromGroupedData(data);
            const average = getAverageValue(value, data.length);
            return {
                name: label,
                value,
                average
            };
        });
    }
};

interface GroupedData {
    data: ChartData[],
    label: string
}

const groupByIntervalWithLabel = (data: ChartData[], interval: ChartInterval): GroupedData[] => {
    const groups: { data: ChartData[]; label: string }[] = [];
    const endDate = last(data)?.date;
    if (endDate)
        for (const current of data) {
            const label = getIntervalLabel(current.date, interval, endDate);
            const group = groups.find(g => g.label === label);

            if (group) {
                group.data.push(current);
            } else {
                groups.push({data: [current], label});
            }
        }

    return groups;
};

const getIntervalLabel = (date: string, interval: ChartInterval, endDate: string) => {
    const momentDate = moment(date);

    const getLastDaysLabel = (daysCount: number) => {
        const momentEndDate = moment(endDate);
        const daysFromEnd = momentEndDate.diff(momentDate, 'days');
        if (daysFromEnd < daysCount - 1) {
            const newStartDate = momentEndDate.clone().subtract(daysCount - 1, 'days');
            return newStartDate.format('DD.MM.YYYY') + ' - ' + momentEndDate.format('DD.MM.YYYY');
        } else {
            const multiplier = Math.floor(daysFromEnd / daysCount);
            const newEndDate = momentEndDate.clone().subtract(multiplier * daysCount, 'days');
            const newStartDate = newEndDate.clone().subtract(daysCount - 1, 'days');
            return newStartDate.format('DD.MM.YYYY') + ' - ' + newEndDate.format('DD.MM.YYYY');
        }
    };


    switch (interval) {
        case 'WEEK':
            return momentDate.startOf('week').format('DD.MM.YYYY') + ' - ' + momentDate.endOf('week').format('DD.MM.YYYY');
        case 'MONTH':
            return momentDate.startOf('month').format('MM.YYYY');
        case 'QUARTER':
            return momentDate.startOf('quarter').format('[Q]Q.YYYY');
        case 'YEAR':
            return momentDate.startOf('year').format('YYYY');
        case "LAST_7_DAYS":
            return getLastDaysLabel(7);
        case "LAST_30_DAYS":
            return getLastDaysLabel(30);
        case "LAST_90_DAYS":
            return getLastDaysLabel(90);
        case "LAST_365_DAYS":
            return getLastDaysLabel(365);
        default:
            return momentDate.format('DD.MM.YYYY');
    }
};

const getValueFromGroupedData = (data: ChartData[]) => {
    const distinctIds = new Set<number>();

    return data.reduce((sum, entry) => {
        if (isArray(entry.value)) {
            // If value is an array, add distinct IDs to the set
            entry.value.forEach(clientId => distinctIds.add(clientId));
            return sum;
        } else {
            // If value is a number, add it to the sum
            return sum + entry.value;
        }
    }, 0) + distinctIds.size;
};

const getAverageValue = (value: number, length: number) => {
    if (length > 0)
        return value / length;
    else return value
};

export const getPrevDataKey = (valueKey: 'value' | 'change' | 'average', prevChartData?: ChartData[]) => {
    if (!prevChartData)
        return undefined;
    switch (valueKey) {
        case "value":
            return "prevValue";
        case "average":
            return "prevAverage";
        case "change":
            return undefined;
    }
};


export const transactionTooltipFormat = (value: number, format: string = WHOLE_NUMBER_FORMAT) => {
    const formattedNumber = formatNumber(value, format);
    let suffix = '';
    if (value === 1)
        suffix = 'transakcja';
    else if ([2, 3, 4].includes(value))
        suffix = 'transakcje';
    else
        suffix = 'transakcji';
    return formattedNumber + ' ' + suffix;
};

export const clientsTooltipFormat = (value: number, format: string = WHOLE_NUMBER_FORMAT) => {
    const formattedNumber = formatNumber(value, format);
    let suffix = '';
    if (value === 1)
        suffix = 'klient';
    else
        suffix = 'klientów';
    return formattedNumber + ' ' + suffix;
};

export const preparePieChartData = (
    data: PieChartData[],
    isEntrySelected: (entryId: number | string) => boolean,
    prevData?: PieChartData[],
): PreparedPieChartData[] => {
    return data.reduce<PreparedPieChartData[]>((acc, entry, index) => {
        if (entry.value === 0) return acc;

        const isSelected = isEntrySelected(entry.id);
        const baseEntry: PreparedPieChartData = {
            ...entry,
            checked: isSelected,
            prevValue: prevData?.[index]?.value,
        };
        // If range is selected and has filtered part, divide into two slices on PieChart
        if (entry.filtered_value !== null && isSelected) {
            acc.push(
                {...baseEntry, value: entry.value - entry.filtered_value, checked: false},
                {...baseEntry, value: entry.filtered_value, checked: true}
            );
        } else {
            acc.push(baseEntry);
        }

        return acc;
    }, []);
};

export const getPieChartColorById = (id: number | string): string => {
    const TEMP_ID_PREFIX = 'temp_';
    if (typeof id === 'string') {
        if (id.startsWith(TEMP_ID_PREFIX)) {
            const tempId = parseInt(id.replace(TEMP_ID_PREFIX, ''), 10);
            return PIE_CHART_COLORS[tempId % PIE_CHART_COLORS.length];
        } else if (id in GENDER_COLORS) {
            return GENDER_COLORS[id as keyof typeof GENDER_COLORS]
        }
    }
    return PIE_CHART_COLORS[Math.abs(Number(id)) % PIE_CHART_COLORS.length];
};

export const PIE_CHART_COLORS = [
    '#04C9C1',
    '#08F49F',
    '#213EFB',
    '#0072FB',
    '#019CE0'
];

export const GENDER_COLORS = {
    male: '#08F49F',
    female: '#213EFB',
    unknown: '#019CE0'
};

export const PREV_COLOR = '#00F6A1';
export const CURRENT_COLOR = '#0071FC';
export const AXIS_COLOR = '#989898';


export const BALANCE_PIE_CHART_COLORS = [
    '#0072FB',
    '#213EFB',
    '#08F49F',
    '#989898'
];

